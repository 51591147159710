import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Pressable, StyleSheet, View, Modal, Platform, StyleProp, TextStyle } from 'react-native';
import { fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import Animated, { cancelAnimation, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import * as COLORS from 'constants/colors';
import Text from 'components/text';
import { windowHeight, windowWidth } from 'constants/sizes';
import { getGlobalPosition } from 'utils/global-position';

import { TooltipTilePlacements, PositionCoords } from '.';

const isAndroid = Platform.OS === 'android';

interface Props {
  isVisible: boolean;
  position: PositionCoords;
  iconSize: number;
  tooltipTileWidth: number;
  placement: TooltipTilePlacements;
  text: string;
  fixedHorizontalOffset: boolean;
  onClose: () => void;
  tooltipTextStyle?: StyleProp<TextStyle>;
  content?: ReactNode;
}

const TooltipTile: FC<Props> = ({
  isVisible,
  position,
  iconSize,
  tooltipTileWidth,
  placement,
  text,
  onClose,
  content = null,
  tooltipTextStyle = null,
  fixedHorizontalOffset = false,
}) => {
  const rootRef = useRef<View>(null);
  const tooltipTileRef = useRef<View>(null);
  const insets = useSafeAreaInsets();

  const [screenOffset, setScreenOffset] = useState<PositionCoords | null>(null);
  const [contentLayoutWidth, setContentLayoutWidth] = useState<number>(tooltipTileWidth);
  const iconOffset = iconSize / 2;

  const measureScreen = async () => {
    const { pageX, pageY } = await getGlobalPosition(rootRef);
    setScreenOffset({
      pageX: pageX || 0,
      pageY: pageY || 0,
    });
  };

  const measureContentTile = async () => {
    const { width } = await getGlobalPosition(tooltipTileRef);
    setContentLayoutWidth(width);
  };

  const contentTilePositionStyle = useMemo(() => {
    const leftIndent = position.pageX - paddingHorizontal(15);
    const rightIndent = windowWidth - position.pageX - paddingHorizontal(15);
    const centerPosition = -(contentLayoutWidth / 2);
    const MAX_LEFT_OFFSET = -(15 + (screenOffset?.pageX || 0));
    const MAX_RIGHT_OFFSET = -(contentLayoutWidth + 15 - (screenOffset?.pageX || 0));

    let positionOffset: number = centerPosition;

    if (!fixedHorizontalOffset) {
      if (leftIndent < contentLayoutWidth / 2) {
        const offset = centerPosition + (contentLayoutWidth / 2 - leftIndent + paddingHorizontal(15));
        positionOffset = offset > MAX_LEFT_OFFSET ? MAX_LEFT_OFFSET : offset;
      }

      if (rightIndent < contentLayoutWidth / 2) {
        const offset = -(contentLayoutWidth - rightIndent - (screenOffset?.pageX || 0));
        positionOffset = offset > MAX_RIGHT_OFFSET ? offset : MAX_RIGHT_OFFSET;
      }
    }

    return {
      left: positionOffset,
    };
  }, [position.pageX, contentLayoutWidth, screenOffset?.pageX, fixedHorizontalOffset]);

  const getContentPlacement = (): TooltipTilePlacements => {
    if (placement === 'auto') {
      if (position.pageY < windowHeight / 2) {
        return 'bottom';
      } else {
        return 'top';
      }
    }

    return placement;
  };

  useEffect(() => {
    if (isVisible) {
      requestAnimationFrame(() => {
        measureScreen();
        measureContentTile();
      });
    } else {
      setScreenOffset(null);
    }
  }, [isVisible]);

  const opacitySharedValue = useSharedValue<number>(0);

  const opacityAnimatedStyle = useAnimatedStyle(
    () => ({
      opacity: opacitySharedValue.value,
    }),
    [opacitySharedValue.value],
  );

  useEffect(() => {
    if (isVisible && screenOffset !== null) {
      opacitySharedValue.value = withTiming(1, { duration: isAndroid ? 1 : 350 });
    } else {
      opacitySharedValue.value = 0;
    }

    return () => {
      cancelAnimation(opacitySharedValue);
    };
  }, [isVisible, opacitySharedValue, screenOffset]);

  const contentPlacement = getContentPlacement();

  const tooltipPositionStyle = {
    top: position.pageY - (screenOffset?.pageY || 0) - (isAndroid ? insets.top : 0),
    left: position.pageX - (screenOffset?.pageX || 0),
  };

  const contentContainerTopStyle = {
    bottom: iconOffset + paddingVertical(15),
  };

  const contentContainerBottomStyle = {
    top: iconOffset + paddingVertical(15),
  };

  const triangleTopStyle = {
    bottom: iconOffset + paddingVertical(5),
    transform: [
      {
        rotate: '180deg',
      },
    ],
  };

  const triangleBottomStyle = {
    top: iconOffset + paddingVertical(5),
  };

  return (
    <Modal transparent={true} visible={isVisible} animationType={'none'} presentationStyle={'overFullScreen'}>
      <Pressable ref={rootRef} style={styles.root} onPress={onClose}>
        <Animated.View style={[styles.tooltipContainer, tooltipPositionStyle, opacityAnimatedStyle]}>
          <Pressable
            style={[
              styles.contentContainer,
              contentPlacement === 'top' ? contentContainerTopStyle : contentContainerBottomStyle,
              contentTilePositionStyle,
            ]}
            onPress={onClose}>
            {content ? (
              <View ref={tooltipTileRef} style={styles.contentWrapper}>
                {content}
              </View>
            ) : (
              <View style={[styles.contentWrapper, styles.content, { width: tooltipTileWidth }]}>
                <Text style={[styles.text, tooltipTextStyle]}>{text}</Text>
              </View>
            )}
          </Pressable>
          <View style={[styles.triangle, contentPlacement === 'top' ? triangleTopStyle : triangleBottomStyle]} collapsable={false} />
        </Animated.View>
      </Pressable>
    </Modal>
  );
};

const styles = StyleSheet.create({
  root: {
    ...StyleSheet.absoluteFillObject,
  },
  tooltipContainer: {
    position: 'absolute',
    height: 1,
    width: 1,
  },
  contentContainer: {
    position: 'absolute',
  },
  contentWrapper: {
    backgroundColor: COLORS.BEIGE_2,
    borderRadius: sw(15),
    justifyContent: 'center',
    elevation: 3,
    shadowColor: COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.4,
    shadowRadius: 2,
  },
  content: {
    paddingVertical: paddingVertical(10),
    paddingLeft: paddingHorizontal(10),
    paddingRight: paddingHorizontal(15),
    maxWidth: windowWidth - paddingHorizontal(30),
    minHeight: sw(40),
  },
  text: {
    fontSize: fs(12),
    lineHeight: fs(18),
    color: COLORS.DARK_GREY_BLUE,
    textAlign: 'left',
    fontWeight: '600',
  },
  triangle: {
    position: 'absolute',
    width: 0,
    height: 0,
    backgroundColor: COLORS.TRANSPARENT,
    borderStyle: 'solid',
    borderLeftWidth: sw(8),
    borderRightWidth: sw(8),
    borderBottomWidth: sw(15),
    borderLeftColor: COLORS.TRANSPARENT,
    borderRightColor: COLORS.TRANSPARENT,
    borderBottomColor: COLORS.BEIGE_2,
    alignSelf: 'center',
  },
});

export default memo(TooltipTile);
