import format from 'string-format';

import type { Currency } from 'modules/payments/constants';
import { type Product as PaymentProduct } from 'modules/payments/interfaces/product';
import { PRODUCT_TYPES, REPORTS_PRODUCTS } from 'modules/payments/constants/product';
import { t2 } from 'localization';
import { getCurrencySymbol } from 'modules/payments/utils';

import { Pack, PackProduct, SubsProduct, AdvisorSoftCurrencyConfig } from '../constants/interfaces';

export const convertMinutesToSoftCurrency = (minutes: number, rate: number = 60) => {
  return minutes * rate;
};

const getPricePerMinute = (pack: Pack) => {
  const { price, currency, minutesAmount } = pack;

  const pricePerMin = price / minutesAmount;
  const currencySymbol = getCurrencySymbol(currency);
  return `${currencySymbol}${pricePerMin.toFixed(2)}`;
};

export const getPricePerSoftCurrency = (pack: Pack, rate: number = 60) => {
  const { price, currency, minutesAmount } = pack;

  const pricePerMin = price / minutesAmount;
  const pricePerSoftCurrency = pricePerMin / rate;
  const currencySymbol = getCurrencySymbol(currency);

  return `${currencySymbol}${pricePerSoftCurrency.toFixed(2)}`;
};

export const getPriceText = (text: string, product: PackProduct | SubsProduct) => {
  const p = { ...product };
  if (p.type === PRODUCT_TYPES.SUBSCRIPTION && p?.period) {
    p.period = t2(`SUBSCRIPTION.PERIODS.${p.period}`);
  }

  return format(text, p);
};

export const preparePackToProductByCurrency = (pack: Pack, currency: Currency, softCurrencyConfig?: AdvisorSoftCurrencyConfig): PackProduct => {
  const softCurrencyAmount = softCurrencyConfig?.enabled ? convertMinutesToSoftCurrency(pack.minutesAmount, softCurrencyConfig?.rate) : 0;

  const p = !pack[currency]
    ? {
        pricePerMin: getPricePerMinute(pack),
        softCurrencyAmount,
        softCurrencyRate: softCurrencyConfig?.rate,
        pricePerSoftCurrency: getPricePerSoftCurrency(pack, softCurrencyConfig?.rate),
        ...pack,
      }
    : {
        ...pack,
        ...pack[currency],
        currency,
        pricePerMin: getPricePerMinute(pack),
        softCurrencyAmount,
        softCurrencyRate: softCurrencyConfig?.rate,
        pricePerSoftCurrency: getPricePerSoftCurrency(pack, softCurrencyConfig?.rate),
      };

  return {
    ...p,
    id: `${REPORTS_PRODUCTS.ASTROLOGER_CONSULTATION}_${pack?.minutesAmount * 60}`,
    productId: p?.minutesAmount.toString(),
    type: PRODUCT_TYPES.ONE_TIME,
    pricePerMin: getPricePerMinute(p),
  };
};

export const prepareProductToPaymentProduct = (product: PackProduct | SubsProduct): PaymentProduct => ({
  id: product.id,
  price: product.price,
  currency: product.currency,
  title: product.title,
  priceText: product.activePrice,
  period: product.type === PRODUCT_TYPES.SUBSCRIPTION && product?.period ? product.period : undefined,
  textLineThrough: product.notActivePrice,
  type: product.type,
});
