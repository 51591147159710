import COINS_ICON from './icon/coins.png';
import GEM_VIOLET_ICON from './icon/gem-violet.png';
import GEM_BLUE_ICON from './icon/gem-blue.png';

export enum SOFT_CURRENCY_TYPES {
  COINS = 'coins',
  GEMS_VIOLET = 'gems_violet',
  GEMS_BLUE = 'gems_blue',
}

export const SOFT_CURRENCY_ICONS = {
  [SOFT_CURRENCY_TYPES.COINS]: COINS_ICON,
  [SOFT_CURRENCY_TYPES.GEMS_VIOLET]: GEM_VIOLET_ICON,
  [SOFT_CURRENCY_TYPES.GEMS_BLUE]: GEM_BLUE_ICON,
};
