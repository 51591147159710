import React, { FC, memo, useRef } from 'react';
import { StyleSheet, View, Image, TouchableOpacity, ViewStyle, StyleProp } from 'react-native';
import { color, fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import LinearGradient from 'react-native-linear-gradient';

import STAR from 'assets/icons/chat/star.png';
import Text from 'components/text';
import * as COLORS from 'constants/colors';
import Button from 'components/button';
import Tooltip, { TooltipRef } from 'components/tooltip';
import { Astrologist } from 'api/astrology-chat/constants';
import { t } from 'localization';
import { TOP_TAB_IDS } from 'screens/advisors/constants';
import PREMIUM_ICON from 'assets/icons/premium.png';
import ActiveStatus from 'components/advisors/active-status';
import { useAppSelector } from 'store';
import { selectAdvisorIsOnline } from 'store/astrologers/selectors';

const GRADIENT_START = { x: 0, y: 0 };
const GRADIENT_END = { x: 0, y: 1 };

const PREMIUM_GRADIENT_START = { x: 0, y: 0.5 };
const PREMIUM_GRADIENT_END = { x: 0.5, y: 1 };

interface Props {
  advisor: Astrologist;
  onPress?: (id: string | number, destination: TOP_TAB_IDS) => void;
  isPreview?: boolean;
  style?: StyleProp<ViewStyle>;
}

const AdvisorCard: FC<Props> = ({ advisor, onPress, style = null, isPreview = false }) => {
  const isOnline = useAppSelector(state => selectAdvisorIsOnline(state, advisor.astrologer_id));
  const tooltipRef = useRef<TooltipRef>(null);

  const handlePress = () => {
    onPress?.(advisor.astrologer_id, TOP_TAB_IDS.ABOUT);
  };

  const handleChatButtonPress = () => {
    if (!isOnline) {
      return tooltipRef.current?.showTooltip();
    }
    onPress?.(advisor.astrologer_id, TOP_TAB_IDS.CHAT);
  };

  if (!advisor) {
    return null;
  }

  const isPremium = !!advisor.is_premium;

  return (
    <TouchableOpacity onPress={handlePress}>
      {isPremium && (
        <LinearGradient
          style={[styles.gradientPremium, style, isPreview && styles.rootPreview]}
          start={PREMIUM_GRADIENT_START}
          end={PREMIUM_GRADIENT_END}
          locations={[0.19, 0.56, 0.93]}
          colors={['#E5A87F', '#F7E6C4', '#E5A87F']}
        />
      )}

      <View style={[styles.root, style, isPreview && styles.rootPreview, isPremium && styles.rootPremium]}>
        <View style={styles.container}>
          <Image source={advisor.avatar} style={[styles.image, isPreview && styles.imagePreview]} />

          {isPremium && (
            <View style={styles.wrPremiumIcon}>
              <Image source={PREMIUM_ICON} style={styles.premiumIcon} />
              {!isPreview && <Text style={styles.premiumTxt}>{t('ADVISORS_CATALOG.PREMIUM_TEXT')}</Text>}
            </View>
          )}

          <View style={styles.wr}>
            <LinearGradient style={styles.gradient} start={GRADIENT_START} end={GRADIENT_END} colors={COLORS.GRADIENT_CARD_TRANSPARENT}>
              <View style={[styles.name, isPreview && styles.namePreview]}>
                <Text font={isPreview ? 'OpenSans' : 'Philosopher'} style={[styles.title, isPreview && styles.titlePreview]} numberOfLines={1}>
                  {advisor.name}
                </Text>

                <ActiveStatus isOnline={isOnline} />
              </View>
            </LinearGradient>
          </View>

          {isPreview ? null : (
            <View style={styles.content}>
              <Text style={styles.subtitle}>{advisor.experience}</Text>

              <View style={styles.rating}>
                <View style={styles.ratingStar}>
                  <Image style={styles.ratingStarIcon} source={STAR} />
                  <View style={styles.ratingStarShadow} />
                </View>

                <Text style={styles.ratingNumber}>{advisor.rating}</Text>
                <View style={styles.rectangle} />
                <Text style={styles.ratingText} numberOfLines={1}>
                  {t('ADVISORS_CATALOG.REVIEWS', { reviews: advisor.reviews })}
                </Text>
              </View>

              <View style={styles.buttonWrapper}>
                <Tooltip
                  ref={tooltipRef}
                  style={styles.tooltip}
                  tooltipText={t('ASTROLOGIST_CHAT.ADVISOR_OFFLINE')}
                  tooltipPlacement={'auto'}
                  tooltipWidth={sw(140)}
                  tooltipTextStyle={styles.tooltipText}
                  iconSize={sw(25)}
                  iconOpacity={0}
                  closingTimeout={3000}
                  fixedHorizontalOffset
                  withIcon
                />
                <Button
                  fontFamily={'OpenSans'}
                  onPress={handleChatButtonPress}
                  title={t('ADVISORS_CATALOG.CHAT')}
                  style={styles.button}
                  titleStyle={styles.buttonTitle}
                  notActive={!isOnline}
                />
              </View>
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: '100%',
    borderRadius: sw(15),
    overflow: 'hidden',
  },
  rootPremium: {
    padding: 2,
  },
  gradientPremium: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: sw(15),
  },
  rootPreview: {
    width: sw(90),
    height: sw(95),
    marginRight: paddingHorizontal(15),
  },
  container: {
    width: '100%',
    height: '100%',
    borderRadius: sw(15),
    overflow: 'hidden',
    justifyContent: 'flex-end',
  },
  wrPremiumIcon: {
    position: 'absolute',
    top: 7,
    left: 7,
    backgroundColor: color(COLORS.DARK_BLUE_GREY_2, 0.9),
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 100,
    padding: 2,
  },
  premiumIcon: {
    width: 18,
    height: 18,
  },
  premiumTxt: {
    color: COLORS.BEIGE_2,
    fontSize: 11,
    lineHeight: 14,
    fontWeight: '600',
    textAlign: 'center',
    marginLeft: 4,
    marginRight: 8,
  },
  image: {
    width: '100%',
    position: 'absolute',
    top: 0,
    height: '70%',
    borderRadius: sw(15),
  },
  imagePreview: {
    height: sw(95),
  },
  wr: {
    height: sw(40),
    paddingBottom: paddingVertical(3),
  },
  gradient: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'flex-end',
  },
  name: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  namePreview: {
    paddingBottom: paddingVertical(8),
  },
  title: {
    fontSize: fs(18),
    fontWeight: '700',
    color: COLORS.WHITE,
    paddingLeft: paddingHorizontal(8),
    paddingRight: paddingHorizontal(5),
  },
  titlePreview: {
    fontSize: fs(12),
    fontWeight: '500',
    maxWidth: sw(70),
  },
  subtitle: {
    fontSize: fs(12),
    fontWeight: '400',
    color: COLORS.BEIGE_2,
  },
  buttonWrapper: {
    minHeight: sw(28),
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    minHeight: sw(28),
    zIndex: 1,
  },
  buttonTitle: {
    fontSize: fs(15),
    fontWeight: '600',
    color: COLORS.WHITE,
  },
  content: {
    paddingHorizontal: paddingHorizontal(10),
    paddingBottom: paddingVertical(10),
    marginTop: -1,
    backgroundColor: COLORS.DARK_TEAL,
  },
  rating: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: paddingVertical(2),
    paddingBottom: paddingVertical(10),
  },
  ratingStar: {
    height: sw(16),
    width: sw(16),
    marginRight: paddingHorizontal(5),
  },
  ratingStarIcon: {
    height: '100%',
    width: '100%',
  },
  ratingStarShadow: {
    position: 'absolute',
    zIndex: 1,
    height: sw(7),
    width: sw(7),
    shadowColor: COLORS.LIGHT_MUSTARD,
    backgroundColor: COLORS.LIGHT_MUSTARD,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 1,
    shadowRadius: 9,
    elevation: 3,
    borderRadius: sw(10),
    transform: [{ translateX: sw(5) }, { translateY: sw(5) }],
  },
  ratingNumber: {
    fontSize: fs(12),
    fontWeight: '700',
    color: COLORS.BEIGE_WITH_OPACITY_08,
  },
  ratingText: {
    fontSize: fs(11),
    fontWeight: '400',
    color: COLORS.BEIGE_WITH_OPACITY_05,
  },
  rectangle: {
    width: sw(1),
    height: sw(12),
    backgroundColor: COLORS.BEIGE_4,
    marginHorizontal: paddingHorizontal(5),
  },
  tooltip: {
    position: 'absolute',
    zIndex: -1,
  },
  tooltipText: {
    fontSize: fs(14),
    fontWeight: '400',
    color: COLORS.BLACK,
    textAlign: 'center',
  },
});

export default memo(AdvisorCard);
