import moment from 'moment';
import dayjs from 'dayjs';

import { HistoryItem } from 'api/astrology-chat/constants';

const TODAY = moment.utc().local().startOf('day');
const getDateByDay = (date: string) => moment(date).format('YYYY-MM-DD');
export const countDaysDiff = (firstDate?: string, secondDate?: string) => {
  if (!secondDate || !firstDate) {
    return -1;
  }

  return moment(getDateByDay(firstDate)).diff(moment(getDateByDay(secondDate)), 'days');
};

export const isDateToday = (date: string) => {
  return moment(date).isSame(TODAY, 'd');
};

export const generateSectionData = (date: string): HistoryItem => {
  return {
    type: 'section',
    created_at: moment(date).add(1000, 'second').format('YYYY-MM-DD HH:mm:ss'),
    is_viewed: 0,
    message: '',
    animated: false,
    is_answered: 1,
    id: `section_${moment(date).add(1000, 'second').format('YYYY-MM-DD HH:mm:ss')}`,
    is_viewed_by_user: 1,
  };
};

export const transformMessages = (messages: HistoryItem[]) => {
  const messagesWithSections = messages?.reduce<HistoryItem[]>((acc, item, index) => {
    const isFirst = index === 0;
    const separator = generateSectionData(item.created_at);

    if (isFirst) {
      return [separator, { ...item, animated: false }, ...acc];
    }

    const lastItemInAcc = acc[acc.length - 1];
    const isDateDifferentFromTheLastItem = countDaysDiff(item.created_at, lastItemInAcc?.created_at);

    if (isDateDifferentFromTheLastItem !== 0) {
      return [...acc, separator, { ...item, animated: false }];
    }

    return [...acc, { ...item, animated: false }];
  }, []);

  return [...messagesWithSections.reverse()];
};

export const formatSeconds = (seconds: number) => {
  let format = '0[min] s[s]';
  if (seconds > 59) {
    if (seconds % 60 === 0) {
      format = 'm[min]';
    } else {
      format = 'm[min] s[s]';
    }
  }

  if (seconds > 3599) {
    format = 'h[h] m[min] s[s]';
  }

  return dayjs.utc(dayjs.duration(seconds, 'seconds').asMilliseconds()).format(format);
};

export const formatMinutes = (seconds: number) => {
  return Math.round(seconds / 60);
};
