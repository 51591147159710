import React, { FC } from 'react';
import { StyleProp, ViewStyle, StyleSheet, TouchableOpacity, TextStyle, ImageSourcePropType, View, Image, ImageStyle } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { sw, fs, paddingHorizontal } from '@wowmaking/ui/src/utils';

import withHaptic from 'hoc/with-haptic';
import Text from 'components/text';
import ButtonSpinner from 'components/button-spinner';
import * as COLORS from 'constants/colors';
import { FONT_TYPES } from 'constants/fonts';

interface GradientDirection {
  [key: string]: {
    start: { x: number; y: number };
    end: { x: number; y: number };
  };
}

const GRADIENT_DIRECTION: GradientDirection = {
  ltr: {
    start: { x: 0, y: 0 },
    end: { x: 1, y: 0 },
  },
  rtl: {
    start: { x: 1, y: 0 },
    end: { x: 0, y: 0 },
  },
  ttb: {
    start: { x: 0, y: 0 },
    end: { x: 0, y: 1 },
  },
};

interface Props {
  onPress: () => void;
  style?: StyleProp<ViewStyle>;
  title: React.ReactNode | string;
  titleStyle?: StyleProp<TextStyle>;
  subTitle?: string;
  subTitleStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  primary?: boolean;
  isSpinner?: boolean;
  isTransparent?: boolean;
  fontWeightImportant?: boolean;
  icon?: ImageSourcePropType;
  showBadge?: boolean;
  badgeStyle?: StyleProp<ViewStyle>;
  badgeTitle?: string | number;
  badgeTitleStyle?: StyleProp<TextStyle>;
  rightIconStyles?: StyleProp<ImageStyle>;
  rightIcon?: ImageSourcePropType;
  gradient?: string[];
  gradientDirection?: 'ltr' | 'rtl' | 'ttb';
  fontFamily?: FONT_TYPES;
  fontWeight?: string;
  notActive?: boolean;
}

const Button: FC<Props> = ({
  onPress,
  style,
  title,
  titleStyle,
  subTitle,
  subTitleStyle,
  disabled,
  primary,
  isSpinner,
  isTransparent,
  icon,
  showBadge = false,
  badgeTitle = '',
  badgeStyle = null,
  badgeTitleStyle = null,
  rightIcon,
  rightIconStyles,
  gradient,
  gradientDirection = 'ltr',
  fontFamily = 'Philosopher',
  notActive = false,
}) => {
  const gradientColors = disabled || isSpinner || notActive ? COLORS.TEAL_GRADIENT_DARK : gradient || COLORS.TEAL_GRADIENT;
  const Component = isTransparent ? View : LinearGradient;

  const handlePress = () => {
    if (typeof onPress === 'function') {
      onPress();
    }
  };

  return (
    <TouchableOpacity style={styles.buttonContainer} disabled={disabled} onPress={isSpinner || disabled ? undefined : handlePress}>
      <Component
        {...(isTransparent ? null : GRADIENT_DIRECTION[gradientDirection])}
        colors={gradientColors}
        style={[styles.button, primary && styles.primary, !!subTitle && styles.withSubtitle, style]}>
        <View style={styles.titleCt}>
          {icon ? <Image source={icon} style={styles.icon} /> : null}
          <Text
            font={fontFamily}
            style={[styles.title, (isSpinner || disabled || notActive) && styles.titleDisabled, primary && styles.primaryText, titleStyle]}>
            {title}
          </Text>
          {rightIcon ? <Image source={rightIcon} style={[styles.rightIcon, rightIconStyles]} /> : null}
          {showBadge && badgeTitle ? (
            <View style={[styles.badge, badgeStyle]}>
              <Text style={[styles.badgeText, badgeTitleStyle]}>{badgeTitle}</Text>
            </View>
          ) : null}
        </View>
        {!!subTitle && (
          <Text font={fontFamily} style={[styles.subTitle, primary && styles.primarySubText, subTitleStyle]}>
            {subTitle}
          </Text>
        )}
        {isSpinner ? <ButtonSpinner /> : null}
      </Component>
    </TouchableOpacity>
  );
};

export default withHaptic(Button);

const styles = StyleSheet.create({
  buttonContainer: {
    position: 'relative',
    width: '100%',
    borderRadius: sw(30),
  },
  button: {
    paddingHorizontal: paddingHorizontal(13),
    borderRadius: sw(30),
    minHeight: sw(50),
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  primary: {
    borderColor: COLORS.BLACK,
    borderWidth: 1.5,
  },
  primaryText: {
    color: COLORS.BLACK,
  },
  primarySubText: {
    color: COLORS.WARM_GREY,
  },
  withSubtitle: {
    padding: paddingHorizontal(7),
  },
  titleCt: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  icon: {
    marginRight: paddingHorizontal(8),
  },
  rightIcon: {
    marginLeft: paddingHorizontal(8),
  },
  title: {
    color: COLORS.WHITE,
    fontSize: fs(20),
    lineHeight: fs(22),
    textAlign: 'center',
    fontWeight: '700',
  },
  subTitle: {
    color: COLORS.WHITE,
    fontSize: fs(15),
    lineHeight: fs(18),
    textAlign: 'center',
  },
  titleDisabled: {
    opacity: 0.5,
  },
  badge: {
    minWidth: sw(21),
    aspectRatio: 1,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.CORAL,
    marginLeft: paddingHorizontal(4),
  },
  badgeText: {
    fontSize: fs(12),
    color: COLORS.WHITE,
    fontWeight: '600',
    textAlign: 'center',
  },
});
