import React, { FC, memo } from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import LinearGradient from 'react-native-linear-gradient';
import { useDispatch } from 'react-redux';

import Analytics from 'analytics';
import { t } from 'localization';
import * as COLORS from 'constants/colors';
import Text from 'components/text';
import { AppDispatch } from 'store';
import { openChatWithRandomAstrologist } from 'store/astrologers/catalog/actions';

import BG_IMG from '../../../../images/random-block-bg.png';

const RandomAdvisorBlock: FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const handlePress = () => {
    Analytics.trackEvent('AdvisorsCatalog_Random', 'Click');
    dispatch(openChatWithRandomAstrologist());
  };

  return (
    <TouchableOpacity style={styles.root} onPress={handlePress}>
      <Image style={styles.bgImg} source={BG_IMG} />
      <View style={styles.container}>
        <Text font="Philosopher" style={styles.title} numberOfLines={3} adjustsFontSizeToFit minimumFontScale={0.8}>
          {t('ADVISORS_CATALOG.RANDOM.TITLE')}
        </Text>
        <View style={styles.button}>
          <LinearGradient style={styles.buttonGradient} colors={COLORS.TEAL_GRADIENT} start={{ x: 0, y: 1 }} end={{ x: 0, y: 0 }} />
          <Text style={styles.btnTitle} numberOfLines={1} adjustsFontSizeToFit minimumFontScale={0.7}>
            {t('ADVISORS_CATALOG.CHAT')}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default memo(RandomAdvisorBlock);

const styles = StyleSheet.create({
  root: {
    width: '100%',
    height: sw(80),
    backgroundColor: COLORS.DARK_TEAL_05,
    marginBottom: paddingVertical(30),
    borderRadius: sw(15),
    overflow: 'hidden',
  },
  bgImg: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
  },
  container: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    paddingHorizontal: paddingHorizontal(15),
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: COLORS.WHITE,
    fontSize: fs(18),
    lineHeight: fs(22),
    fontWeight: '700',
    textAlign: 'left',
    paddingRight: paddingHorizontal(10),
    flex: 1,
  },
  button: {
    width: sw(110),
    height: sw(36),
    borderRadius: sw(50),
    justifyContent: 'center',
    alignContent: 'center',
    overflow: 'hidden',
  },
  buttonGradient: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  btnTitle: {
    color: COLORS.WHITE,
    fontSize: fs(16),
    fontWeight: '600',
    textAlign: 'center',
    paddingHorizontal: paddingHorizontal(10),
    zIndex: 1,
  },
});
