import React, { FC, memo } from 'react';
import { StyleSheet, Image, View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import * as COLORS from 'constants/colors';
import Text from 'components/text';
import { Astrologist } from 'api/astrology-chat/constants';
import { useAppSelector } from 'store';
import { selectAdvisorIsOnline, selectHoroscopeAdvisorId } from 'store/astrologers/selectors';
import PREMIUM_ICON from 'assets/icons/premium.png';

import ActiveStatus from '../active-status';

import LABEL_IMG from './images/label.png';
import LABEL_PREMIUM_IMG from './images/label-premium.png';
import { AdvisorAvatarSizes, GRADIENT_DIRECTION, sizes } from './constants';

interface Props {
  advisor: Astrologist;
  size: AdvisorAvatarSizes;
  nameEnabled?: boolean;
  unreadCount?: number;
  alwaysOnline?: boolean;
}

const AdvisorAvatar: FC<Props> = ({ advisor, size, nameEnabled = false, unreadCount = 0, alwaysOnline = false }) => {
  const horoscopeAdvisorId = useAppSelector(selectHoroscopeAdvisorId);
  const avatarHoroscopeLabelEnabled = useAppSelector(state => state.remoteConfig.avatarHoroscopeLabelEnabled);
  const advisorStatus = useAppSelector(state => selectAdvisorIsOnline(state, advisor.astrologer_id));

  const isOnline = alwaysOnline || advisorStatus;
  const isPremium = !!advisor.is_premium;
  const isHoroscope = avatarHoroscopeLabelEnabled && `${horoscopeAdvisorId}` === `${advisor.astrologer_id}`;

  const configSizes = sizes[size];

  return (
    <View style={styles.root}>
      <View style={[styles.avatarContainer, { width: configSizes.width, height: configSizes.width }]}>
        {isPremium ? (
          <LinearGradient
            style={styles.gradient}
            start={GRADIENT_DIRECTION.START}
            end={GRADIENT_DIRECTION.END}
            locations={[0.19, 0.56, 0.93]}
            colors={['#E5A87F', '#F7E6C4', '#E5A87F']}
          />
        ) : (
          <View style={styles.bgColor} />
        )}

        <View style={styles.avatarWr}>
          <Image source={advisor.avatar} style={styles.avatarImage} />
        </View>

        {isHoroscope && <Image source={isPremium ? LABEL_PREMIUM_IMG : LABEL_IMG} style={styles.label} />}

        <ActiveStatus isOnline={isOnline} style={[styles.status, { right: configSizes.dotIndent }]} />

        {isPremium && (
          <View style={[styles.wrPremiumIcon, { right: configSizes.premiumIconIndent, top: configSizes.premiumIconIndent }]}>
            <Image source={PREMIUM_ICON} style={styles.premiumIcon} />
          </View>
        )}

        {unreadCount ? (
          <View style={styles.unreadMsgBadge}>
            <Text style={styles.unreadMsgTitle}>{unreadCount}</Text>
          </View>
        ) : null}
      </View>

      {nameEnabled && <Text style={[styles.name, { marginTop: configSizes.nameIndent }]}>{advisor.name}</Text>}
    </View>
  );
};
const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 100,
  },
  bgColor: {
    backgroundColor: COLORS.DARK_GREY_BLUE,
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 100,
  },
  avatarWr: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
    padding: 2,
  },
  avatarImage: {
    width: '100%',
    height: '100%',
    borderColor: COLORS.TRANSPARENT,
    borderRadius: 100,
    resizeMode: 'contain',
  },
  label: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  status: {
    position: 'absolute',
  },
  wrPremiumIcon: {
    position: 'absolute',
    width: 18,
    height: 18,
  },
  premiumIcon: {
    width: '100%',
    height: '100%',
  },
  name: {
    fontSize: 12,
    fontWeight: '400',
    lineHeight: 16,
    color: COLORS.BEIGE,
    textAlign: 'center',
  },
  unreadMsgBadge: {
    position: 'absolute',
    minWidth: 18,
    height: 18,
    top: 1,
    left: 2,
    borderRadius: 100,
    backgroundColor: COLORS.CORAL,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 1,
    paddingHorizontal: 4,
    paddingBottom: 4,
  },
  unreadMsgTitle: {
    color: COLORS.WHITE,
    fontSize: 11,
    fontWeight: '600',
    textAlign: 'center',
  },
});

export default memo(AdvisorAvatar);
