import React, { FC } from 'react';
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, ViewStyle } from 'react-native';
import { fs, paddingHorizontal, sw } from '@wowmaking/ui/src/utils';

import * as COLORS from 'constants/colors';
import Text from 'components/text';
import Icon from 'components/icon';

interface Props {
  title: string;
  onPress: () => void;
  titleUnderline?: boolean;
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

const ActionButton: FC<Props> = ({ title, onPress, titleUnderline = false, style = null, textStyle = null }) => {
  return (
    <TouchableOpacity style={[styles.wr, style]} onPress={onPress}>
      <Text style={[styles.title, textStyle, titleUnderline && styles.underlineTxt]}>{title}</Text>
      <Icon style={styles.icon} name={'arrow'} size={sw(12)} color={COLORS.AQUA} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  wr: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontSize: fs(14),
    fontWeight: '600',
    color: COLORS.AQUA,
    textAlign: 'center',
    marginRight: paddingHorizontal(5),
  },
  underlineTxt: {
    textDecorationLine: 'underline',
  },
  icon: {
    marginTop: 2,
  },
});

export default ActionButton;
