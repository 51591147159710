import React, { FC, memo } from 'react';
import { StyleSheet } from 'react-native';
import { fs } from '@wowmaking/ui/src/utils';

import { useAppDispatch, useAppSelector } from 'store';
import { ADVISORS_ROUTES, ADVISORS } from 'constants/routes';
import { removeTriggerNotifications } from 'store/astrologers/notifications/actions';
import { navigate } from 'store/navigation/actions';
import TIMER from 'assets/icons/timer/timer.png';
import { formatMinutes } from 'utils/astrologist';
import { t, t2 } from 'localization';
import { selectTriggerNotificationSeconds } from 'store/astrologers/notifications/selectors';
import { convertMinutesToSoftCurrency } from 'screens/advisors/screens/monetization/utils';
import Text from 'components/text';
import { DARK_TEAL } from 'constants/colors';
import { getSoftCurrencyIcon } from 'components/advisors/soft-currency/utils';

import BaseTriggerNotification from './base';

import { EXTERNAL_TRIGGER_NOTIFICATIONS } from './index';

interface Props {}

const SuccessAfterTrigger: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const seconds = useAppSelector(state => selectTriggerNotificationSeconds(state, EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER));
  const currentRoute = useAppSelector(state => state.navigation.currentRoute);
  const softCurrencyConfig = useAppSelector(state => state.remoteConfig.advisorSoftCurrencyConfig);

  const handlePress = () => {
    if (!ADVISORS_ROUTES.includes(currentRoute)) {
      navigate(ADVISORS);
    }

    dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER]));
  };

  const handleClose = () => {
    dispatch(removeTriggerNotifications([EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER]));
  };

  if (!seconds) {
    return null;
  }

  const softCurrency = t2(`SOFT_CURRENCY.${softCurrencyConfig.type.toUpperCase()}`);
  const softCurrencyIcon = getSoftCurrencyIcon(softCurrencyConfig.type);

  return (
    <BaseTriggerNotification
      onPress={handlePress}
      onClose={handleClose}
      icon={softCurrencyConfig.enabled ? softCurrencyIcon : TIMER}
      type={EXTERNAL_TRIGGER_NOTIFICATIONS.SUCCESS_AFTER_TRIGGER}>
      <Text style={styles.text}>
        {t(`TRIGGER_NOTIFICATION.TRIGGER_SUCCESS${softCurrencyConfig.enabled ? '_SOFT_CRC' : ''}`, {
          minutes: formatMinutes(seconds),
          amount: convertMinutesToSoftCurrency(seconds / 60, softCurrencyConfig.rate),
          currency: softCurrency,
        })}
      </Text>
    </BaseTriggerNotification>
  );
};

const styles = StyleSheet.create({
  text: {
    fontSize: fs(12),
    fontWeight: '600',
    color: DARK_TEAL,
    lineHeight: fs(20),
    flex: 1,
    height: '100%',
  },
});

export default memo(SuccessAfterTrigger);
