import React, { FC, memo, useCallback, useMemo } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import { isEmpty, some } from 'lodash';

import Text from 'components/text';
import * as COLORS from 'constants/colors';
import { useAppDispatch, useAppSelector } from 'store';
import { getAdvisorsByCategoryId } from 'store/astrologers/selectors';
import { t2 } from 'localization';
import Analytics from 'analytics';
import { selectAnAdvisor } from 'store/astrologers/catalog/actions';
import { TOP_TAB_IDS } from 'screens/advisors/constants';
import ProLabel from 'components/pro-label';
import * as TRIGGERS from 'constants/monetization-triggers';

import { ADVISORS_CATEGORIES } from '../../types';
import AdvisorCard from '../advisor-card';

interface Props {
  categoryId: ADVISORS_CATEGORIES;
}

const AdvisorsCategory: FC<Props> = ({ categoryId }) => {
  const dispatch = useAppDispatch();

  const hasSubscription = useAppSelector(state => state.billing.purchased);
  const advisors = useAppSelector(state => getAdvisorsByCategoryId(state, categoryId));
  const monetizationConfig = useAppSelector(state => state.remoteConfig.monetizationConfig);
  const stealthModeEnabled = useAppSelector(state => state.remoteConfig.stealthModeEnabled);

  const textPath = `ADVISORS_CATALOG.${categoryId.toUpperCase()}`;

  const title = t2(`${textPath}.TITLE`);
  const subtitle = t2(`${textPath}.SUBTITLE`);

  const handleButtonPress = useCallback(
    (id: string | number, destination: TOP_TAB_IDS) => {
      Analytics.trackEvent('AdvisorsCatalog', 'Advisor_Click', {
        id,
        category: categoryId,
      });

      dispatch(selectAnAdvisor(id, destination));
    },
    [categoryId, dispatch],
  );

  const proLabelShow = useMemo(
    () =>
      categoryId === ADVISORS_CATEGORIES.PREMIUM &&
      !hasSubscription &&
      some([TRIGGERS.PREMIUM_ADVISOR_CLICK, TRIGGERS.PREMIUM_ADVISOR_START_CHAT], trg => !isEmpty(monetizationConfig[trg])),
    [categoryId, hasSubscription, monetizationConfig],
  );

  if (!advisors?.length) {
    return null;
  }

  return (
    <View style={styles.root}>
      <View style={styles.wrTitle}>
        <Text font={'Philosopher'} style={styles.title}>
          {title}
        </Text>

        {proLabelShow && <ProLabel size={'large'} style={styles.proLabel} />}
      </View>

      {stealthModeEnabled ? null : <Text style={styles.subtitle}>{subtitle}</Text>}

      <ScrollView
        style={styles.scrollContainer}
        contentContainerStyle={styles.cardContainer}
        showsHorizontalScrollIndicator={false}
        horizontal={true}>
        {advisors.map((item, index) => {
          const isLast = index === advisors.length - 1;
          return <AdvisorCard key={item.astrologer_id} advisor={item} onPress={handleButtonPress} style={[styles.card, isLast && styles.lastCard]} />;
        })}
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    marginBottom: paddingVertical(30),
  },
  wrTitle: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: fs(25),
    fontWeight: '700',
    color: COLORS.BEIGE_2,
    marginBottom: paddingVertical(10),
  },
  proLabel: {
    marginLeft: 10,
  },
  subtitle: {
    fontSize: fs(12),
    fontWeight: '400',
    color: COLORS.BEIGE,
    opacity: 0.7,
    marginBottom: paddingVertical(10),
  },
  scrollContainer: {
    marginHorizontal: paddingHorizontal(-15),
    marginTop: paddingVertical(5),
  },
  cardContainer: {
    paddingHorizontal: paddingHorizontal(15),
  },
  card: {
    marginRight: paddingHorizontal(15),
    height: sw(232),
    width: sw(155),
  },
  lastCard: {
    marginRight: paddingHorizontal(0),
  },
});

export default memo(AdvisorsCategory);
