import { Astrologist, AstrologistReviewItem } from 'api/astrology-chat/constants';

import { ResetUserData } from '../types';

interface AstrologerPalmReadingData {
  hiddenMessage: string;
  image: string;
}

export const QUESTIONS_CATEGORY_ALL = 'all';

export enum QUESTION_TYPES {
  SUGGEST = 'suggest',
  PALM_REQUEST_SUGGEST = 'palmRequestSuggest',
  ASTROLOGERS_PROMO = 'astrologersPromo',
  USER_QUESTION = 'userQuestion',
  HOROSCOPE_REQUEST = 'horoscopeRequest',
}
export interface Question {
  id?: string;
  text: string;
  metadata: {
    type: QUESTION_TYPES;
  };
}

export interface QuestionsCategory {
  id: number;
  slug: string;
  title: string;
  priority: number;
  questions: Question[];
}

export interface Questions {
  [key: string]: QuestionsCategory;
}

export type AstrologistReviews = {
  [key in Astrologist['astrologer_id']]: AstrologistReviewItem[] | null;
};

export interface State {
  wasRequest: boolean;
  activeQuestionsCategory: string;
  questions: Questions;
  astrologist: Astrologist;
  astrologistReviews: AstrologistReviews;
  allAstrologers: Astrologist[];
  lastConnectionSessionNumber: number;
  lastConnectedAstrologer: Astrologist;
  palmReadingData: AstrologerPalmReadingData | null;
  promocode: string;
}

export enum TYPES {
  SET_REQUEST = 'ASTROLOGERS/SET_REQUEST',
  SET_ASTROLOGIST = 'ASTROLOGERS/SET_ASTROLOGIST',
  SET_QUESTIONS_CATEGORY = 'ASTROLOGERS/SET_QUESTIONS_CATEGORY',
  SET_QUESTIONS = 'ASTROLOGERS/SET_QUESTIONS',
  SET_ASTROLOGERS = 'ASTROLOGERS/SET_ASTROLOGERS',
  SET_LAST_CONNECTION_SESSION_NUMBER = 'ASTROLOGERS/SET_LAST_CONNECTION_SESSION_NUMBER',
  SET_LAST_CONNECTED_ASTROLOGER = 'ASTROLOGERS/SET_LAST_CONNECTED_ASTROLOGER',
  SET_ASTROLOGER_PALM_READING_DATA = 'ASTROLOGERS/SET_ASTROLOGER_PALM_READING_DATA',
  SET_PROMOCODE = 'ASTROLOGERS/SET_PROMOCODE',
  SET_ASTROLOGIST_REVIEWS = 'ASTROLOGERS/SET_ASTROLOGIST_REVIEWS',
}

interface SetWasRequest {
  type: typeof TYPES.SET_REQUEST;
  payload: boolean;
}

interface SetAstrologist {
  type: typeof TYPES.SET_ASTROLOGIST;
  payload: Astrologist;
}

interface SetQuestions {
  type: typeof TYPES.SET_QUESTIONS;
  payload: Questions;
}

interface SetQuestionsCategory {
  type: typeof TYPES.SET_QUESTIONS_CATEGORY;
  payload: string;
}

interface SetAiAstrologers {
  type: typeof TYPES.SET_ASTROLOGERS;
  payload: Astrologist[];
}

interface SetLastConnectionSessionNumber {
  type: typeof TYPES.SET_LAST_CONNECTION_SESSION_NUMBER;
  payload: number;
}

interface SetLastConnectedAstrologist {
  type: typeof TYPES.SET_LAST_CONNECTED_ASTROLOGER;
  payload: Astrologist;
}

interface SetAstrologerPalmReadingData {
  type: typeof TYPES.SET_ASTROLOGER_PALM_READING_DATA;
  payload: AstrologerPalmReadingData;
}

interface SetPromocode {
  type: typeof TYPES.SET_PROMOCODE;
  payload: string;
}
interface SetAstrologistReviews {
  type: typeof TYPES.SET_ASTROLOGIST_REVIEWS;
  payload: { id: Astrologist['astrologer_id']; reviews: AstrologistReviewItem[] | null };
}

export type ActionType =
  | SetWasRequest
  | SetAstrologist
  | SetQuestions
  | SetQuestionsCategory
  | SetAiAstrologers
  | SetLastConnectionSessionNumber
  | SetLastConnectionSessionNumber
  | SetLastConnectedAstrologist
  | SetLastConnectionSessionNumber
  | ResetUserData
  | SetAstrologerPalmReadingData
  | SetAstrologistReviews
  | SetPromocode;
