import { RefObject } from 'react';
import { View } from 'react-native';

interface MeasureValues {
  x: number;
  y: number;
  width: number;
  height: number;
  pageX: number;
  pageY: number;
}
export const getGlobalPosition = async (ref: RefObject<View>): Promise<MeasureValues> =>
  new Promise(resolve => {
    ref.current?.measure((x, y, width, height, pageX, pageY) => {
      resolve({
        x,
        y,
        width,
        height,
        pageX,
        pageY,
      });
    });
  });
