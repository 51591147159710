import * as ROUTES from './routes';

export const ONBOARDING_PWA_IDS = {};

export const ONBOARDING_SUB_IDS = {
  SUB_SPECIAL_OFFER: 'SUB_SPECIAL_OFFER',
  SUB_VERTICAL_PRODUCTS: 'SUB_VERTICAL_PRODUCTS',
  SUB_FAMILY: 'SUB_FAMILY',
  SUB_VERTICAL_ONE: 'SUB_VERTICAL_ONE',
  SUB_PALM: 'SUB_PALM',
  SUB_POST: 'SUB_POST',
  SUB_WEB: 'SUB_WEB',
};

export const ONBOARDING_IDS = {
  WELCOME: 'WELCOME',
  NAME: 'NAME',
  PALM: 'PALM',
  GENDER: 'GENDER',
  DATE_OF_BIRTH: 'DATE_OF_BIRTH',
  RELATIONSHIP: 'RELATIONSHIP',
  BIRTH_PLACE: 'BIRTH_PLACE',
  TIME_OF_BIRTH: 'TIME_OF_BIRTH',
  STEALTH_CATEGORIES: 'STEALTH_CATEGORIES',
  PROCESSING: 'PROCESSING',
  ...ONBOARDING_SUB_IDS,
  ...ONBOARDING_PWA_IDS,
};

export const BIRTH_CHART_ONBOARDING_STEPS = {
  WELCOME: 'WELCOME',
  CHART: 'CHART',
  PLANETS: 'PLANETS',
  HOUSES: 'HOUSES',
  TRANSITS: 'TRANSITS',
  PLACE: 'PLACE',
  TIME: 'TIME',
  PRELOADER: 'PRELOADER',
};

export const ONBOARDING_SCREENS = {
  [ONBOARDING_IDS.WELCOME]: ROUTES.ONBOARDING_WELCOME,
  [ONBOARDING_IDS.NAME]: ROUTES.ONBOARDING_NAME,
  [ONBOARDING_IDS.PALM]: ROUTES.ONBOARDING_PALM,
  [ONBOARDING_IDS.GENDER]: ROUTES.ONBOARDING_GENDER,
  [ONBOARDING_IDS.DATE_OF_BIRTH]: ROUTES.ONBOARDING_DATE,
  [ONBOARDING_IDS.RELATIONSHIP]: ROUTES.ONBOARDING_RELATIONSHIP,
  [ONBOARDING_IDS.BIRTH_PLACE]: ROUTES.ONBOARDING_PLACE,
  [ONBOARDING_IDS.TIME_OF_BIRTH]: ROUTES.ONBOARDING_TIME,
  [ONBOARDING_IDS.STEALTH_CATEGORIES]: ROUTES.ONBOARDING_STEALTH_CATEGORIES,
  [ONBOARDING_IDS.PROCESSING]: ROUTES.ONBOARDING_LOADER,

  [ONBOARDING_IDS.SUB_SPECIAL_OFFER]: ROUTES.SUBSCRIPTION_SPECIAL_OFFER,
  [ONBOARDING_IDS.SUB_VERTICAL_PRODUCTS]: ROUTES.SUBSCRIPTION_VERTICAL_PRODUCTS,
  [ONBOARDING_IDS.SUB_FAMILY]: ROUTES.SUBSCRIPTION_FAMILY,
  [ONBOARDING_IDS.SUB_PALM]: ROUTES.SUBSCRIPTION_PALM,
  [ONBOARDING_IDS.SUB_POST]: ROUTES.SUBSCRIPTION_POST,
  [ONBOARDING_IDS.SUB_VERTICAL_ONE]: ROUTES.SUBSCRIPTION_VERTICAL_ONE_PRODUCT,
  [ONBOARDING_IDS.SUB_WEB]: ROUTES.SUBSCRIPTION_WEB,
};
