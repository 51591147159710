import Mutator from '@magnus/react-native-mutator';
import { Platform } from 'react-native';

import Analytics from 'analytics';
import { MAGNUS_TOKEN } from 'constants/general';
import type { AppThunk } from 'store';

import { setMutatorIsFetched } from '../app/actions';

import { setRemoteConfig } from './index';

const fetchInterval = 0; // in seconds
const fetchTimeout = 5000; // in milliseconds
let fetchPromise: Promise<void> | null = null;

export const init = (): AppThunk => async (_dispatch, getState) => {
  const isConnected = getState().app.isInternetConnected;
  if (!isConnected) {
    return;
  }

  Analytics.track('RemoteConfig_Fetch_Start');
  try {
    await fetch();
  } catch (err: any) {
    const message = String(err?.message || err);
    console.log('> [REMOTE CONFIG FETCH ERROR]: ', message);
    Analytics.track('RemoteConfig_Fetch_Error', { message });
  }
};

export const activate = (): AppThunk<Promise<void>> => async (dispatch, getState) => {
  const isConnected = getState().app.isInternetConnected;

  if (!isConnected) {
    return;
  }

  try {
    await fetch();
    const config = await Mutator.activate();

    if (config) {
      Analytics.track('RemoteConfig_Fetch_Success');
      dispatch(setRemoteConfig(config));
      dispatch(setMutatorIsFetched());
    }
  } catch (err: any) {
    const message = String(err?.message || err);
    Analytics.track('RemoteConfig_Fetch_Error', { message });
  }
};

const fetch = () =>
  new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      reject('Mutator fetch timeout');
    }, fetchTimeout);

    if (fetchPromise === null) {
      Mutator.init({
        token: MAGNUS_TOKEN,
        useBackendStorage: Platform.OS === 'web' ? true : false,
      });

      fetchPromise = Mutator.fetch(fetchInterval);

      if (Platform.OS === 'web' && Mutator.getSessionFirst()) {
        Analytics.track('Mutator_Session_First');
      }
    }

    fetchPromise
      .then(() => {
        clearTimeout(timeout);
        resolve('OK');
      })
      .catch(err => {
        clearTimeout(timeout);
        reject(err);
      });
  });
