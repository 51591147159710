import React, { FC, memo } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { fs, paddingVertical, sw } from '@wowmaking/ui/src/utils';

import Text from 'components/text';
import * as COLORS from 'constants/colors';
import { t } from 'localization';
import Icon from 'components/icon';

interface Props {
  onPress: () => void;
}

const ViewAll: FC<Props> = ({ onPress }) => {
  return (
    <TouchableOpacity style={styles.root} onPress={onPress}>
      <View style={styles.wrArrow}>
        <Icon name={'arrow'} size={sw(16)} color={COLORS.AQUA} />
      </View>
      <Text style={styles.text}>{t('ADVISORS_CATALOG.ALL.VIEW_ALL')}</Text>
    </TouchableOpacity>
  );
};

export default memo(ViewAll);

const styles = StyleSheet.create({
  root: {
    width: sw(90),
    height: sw(95),
    borderRadius: sw(10),
    backgroundColor: COLORS.DARK_TEAL,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: fs(14),
    fontWeight: '500',
    color: COLORS.AQUA,
  },
  wrArrow: {
    width: sw(30),
    height: sw(30),
    borderRadius: sw(15),
    backgroundColor: COLORS.DARK_GREY_BLUE,
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: paddingVertical(15),
  },
});
