import React, { FC, useEffect, useState } from 'react';
import { StyleProp, StyleSheet, View, ViewStyle, ActivityIndicator } from 'react-native';
import LottieView from 'lottie-react-native';
import { sw, fs } from '@wowmaking/ui/src/utils';
import Animated, { cancelAnimation, runOnJS, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

import { t } from 'localization';
import { useAppSelector } from 'store';
import * as COLORS from 'constants/colors';
import Text from 'components/text';

import ANIMATION from './animations/loader.json';

interface Props {
  visible: boolean;
  style?: StyleProp<ViewStyle>;
}

const LoaderOverlay: FC<Props> = ({ visible, style }) => {
  const stealthModeEnabled = useAppSelector(state => state.remoteConfig.stealthModeEnabled);

  const [showOverlay, setShowOverlay] = useState(false);
  const opacitySharedValue = useSharedValue(0);

  const rootAnimationStyle = useAnimatedStyle(() => ({
    opacity: opacitySharedValue.value,
  }));

  useEffect(() => {
    if (visible) {
      setShowOverlay(true);
    }
    opacitySharedValue.value = withTiming(+visible, { duration: 250 }, () => {
      if (!visible) {
        runOnJS(setShowOverlay)(false);
      }
    });

    return () => {
      cancelAnimation(opacitySharedValue);
    };
  }, [opacitySharedValue, visible]);

  const styleRoot = StyleSheet.compose(styles.root, style);

  return showOverlay ? (
    <Animated.View style={[styleRoot, rootAnimationStyle]}>
      <View>
        {stealthModeEnabled ? (
          <ActivityIndicator size={'large'} color={COLORS.TOUPE} style={styles.activityIndicator} />
        ) : (
          <LottieView source={ANIMATION} autoPlay style={styles.loader} />
        )}
        <View style={styles.waitTextWrap}>
          <Text style={styles.waitText}>{t('WAIT')}</Text>
        </View>
      </View>
    </Animated.View>
  ) : null;
};

export default LoaderOverlay;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: COLORS.OVERLAY_BACKGROUND,
    ...StyleSheet.absoluteFillObject,
    zIndex: 9999,
  },
  activityIndicator: {
    width: sw(140),
  },
  loader: {
    width: sw(140),
    height: sw(140),
    position: 'relative',
  },
  waitTextWrap: {
    position: 'absolute',
    alignSelf: 'center',
    left: 0,
    right: 0,
    bottom: -25,
  },
  waitText: {
    fontSize: fs(16),
    textAlign: 'center',
    color: COLORS.TOUPE,
  },
});
