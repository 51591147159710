import { Platform } from 'react-native';

import * as ROUTES from 'constants/routes';
import { PUSH_BEHAVIOR } from 'constants/messaging';
import { ZENDESK_TOKEN, SUPPORT_EMAIL, SUPPORT_EMAIL_WEB, HELPDESK_CONFIG, APP_NAME } from 'constants/general';
import { CONTACT_US_METHODS } from 'constants/form';
import { TAB_BAR_BADGES_TYPES } from 'constants/tab-bar-badges';
import { SKIP_BUTTON_PLACE } from 'screens/onboarding/palm/types';
import { WEB_WIDGETS_LIST, READING_PAGE_WIDGETS, COMPATIBILITY_WIDGETS_LIST } from 'constants/widgets';
import { BANNERS as GUIDES_BANNERS } from 'constants/guides';
import { MODAL_CLOSE_TYPES } from 'constants/modal-close-types';
import { SUB_SCREENS } from 'constants/subscription-screens';
import { BADGE_ALIGN, PRICE_LOCATION, PRODUCTS, SUB_FAMILY_TYPES, SUB_TYPES, TEXTS_ALIGN } from 'constants/subscription';
import { ONBOARDING_IDS } from 'constants/onboarding-types';
import { ADVISORS_ONB_PAGES_IDS, ASTROLOGERS_ONBOARDING_INAPP } from 'store/astrologers/onboarding/types';
import { ADVISORS_CATALOG_CUSTOM_BLOCK_IDS, ADVISORS_CATEGORIES } from 'screens/advisors/screens/catalog/types';
import { PRODUCT_IDS, SUBSCRIPTION_IDS, OFFERS } from 'constants/billing';
import { PAYMENT_METHODS } from 'modules/payments/constants';
import { ADVISOR_NOTIFICATIONS } from 'constants/notification-center';
import { TOP_TABS_IDS } from 'screens/birth-chart/constants';
import {
  astrologersMonetizationConfig,
  astrologerConsultationPacks,
  astrologerConsultationDiscountPacks,
  astrologerPackSpecialOffer,
  astrologerPackWelcomeOffer,
  astrologerPackSpecialOffer2,
  astrologerPacksFlows,
  advisorSoftCurrencyConfig,
} from 'screens/advisors/screens/monetization/constants';
import { ASTROLOGERS_PROMO_PLACES, TOP_TAB_IDS } from 'screens/advisors/constants';
import { defaultAstroCalendarConfig, defaultAstroCalendarNotificationConfig } from 'screens/astro-calendar/constants';
import { WEB_SUBSCRIPTION_ID, REPORTS_PRODUCTS } from 'modules/payments/constants/product';
import { ASTROLOGER_TIP_PRODUCTS } from 'screens/advisors/screens/monetization/constants/interfaces';
import { defaultAdvisorsCatalogStealthCategoryPid } from 'screens/advisors/screens/catalog/constants';

import { monetizationConfigAndroid, monetizationConfigIos, monetizationConfigWeb } from './monetization';
import {
  Rescan,
  SubscriptionFamily,
  SubscriptionPalm,
  SubscriptionPost,
  SubscriptionPromoOffer,
  SubscriptionSpecialOffer,
  SubscriptionTimer,
  SubscriptionVertical,
  SubscriptionVerticalOneProduct,
  SurveyConfig,
  PushConfig,
  RateUs,
  WebOnetimeProduct,
  ChatTooltipConfig,
  AstroeventModalConfig,
} from './types';
import type { ParamItem, SubscriptionSubBenefits } from './types';

export enum ParamTypes {
  BOOLEAN = 'boolean',
  JSON = 'json',
  NUMBER = 'number',
  STRING = 'string',
}

const subscriptions: string[] = SUBSCRIPTION_IDS;
const products: string[] = PRODUCT_IDS;
const authZendeskToken = ZENDESK_TOKEN as string;

const pushConfig: PushConfig = {
  afterUse: PUSH_BEHAVIOR.NONE,
  dailyHoroscope: PUSH_BEHAVIOR.ALL,
  other: PUSH_BEHAVIOR.FREE,
};

const surveyConfig: SurveyConfig = Platform.select({
  ios: {
    title: 'Help us improve the app',
    text: `Please take a quick survey so we can make ${APP_NAME} perfect for you!`,
    btnTitle: "Let's do it",
    skipText: 'Maybe later',
    paidUrl: '',
    freeUrl: '',
    sessionNumber: 0,
    maxShowCount: 2,
    intervalBetweenSessions: 3,
  },
  android: {
    title: 'Help us improve',
    text: 'Please take a quick survey so we can make perfect for you',
    btnTitle: 'Start Now!',
    skipText: 'Maybe later',
    paidUrl: '',
    freeUrl: '',
    sessionNumber: 0,
    maxShowCount: 2,
    intervalBetweenSessions: 2,
  },
  web: {
    title: 'Help us improve',
    text: 'Please take a quick survey so we can make Astroline better for you',
    btnTitle: 'Start Now!',
    skipText: 'Maybe later',
    paidUrl: '',
    freeUrl: '',
    sessionNumber: 99999999,
    maxShowCount: 1,
    intervalBetweenSessions: 2,
    version: 1,
  },
}) as SurveyConfig;

export const rateUs: RateUs = {
  enabled: false,
  isCustom: true,
  onboardingStep: '',
  sessionStart: 1000, // For rate us after use feature
  icons: 'stars', // smiles | stars
  nativeDelay: Platform.select({
    ios: 7,
    android: 0,
    web: 0,
  }) as number,
  showInSettings: false,
  minRateToTrustPilot: 4,
  likesCountToShow: 2,
  showOnHomeDelay: 1, // seconds
  showOnHomeSession: 1000,
};

const subscriptionTimer: SubscriptionTimer = {
  showTerms: true,
  time: 240,
  products: [
    {
      title: 'Month',
      productId: Platform.select({
        ios: PRODUCTS.MONTH_F,
        android: PRODUCTS.MONTH_D,
      }) as string,
      isActive: true,
    },
    {
      title: 'Week',
      productId: Platform.select({
        ios: PRODUCTS.WEEK_H,
        android: PRODUCTS.WEEK_F,
      }) as string,
    },
  ],
};

const subscriptionSpecialOffer: SubscriptionSpecialOffer = {
  productId: Platform.select({
    ios: PRODUCTS.YEAR_C,
    android: PRODUCTS.YEAR_C,
  }) as string,
  priceFormat: '{price}/year',
  priceDescFormat: 'thats only {pricemonth}',
  discount: 10,
  time: 240,
  btnTitle: 'TRY 3-DAY TRIAL',
  closeBtnTimeout: 1,
  showTerms: true,
  buttonAnimation: 'waves',
};

const rescan: Rescan = {
  enabled: true,
  timeout: 1, // in days
  whoseHandPopupEnabled: true,
};

const webOnetimeProductsDetails: WebOnetimeProduct[] = [
  {
    product: REPORTS_PRODUCTS.ASTROLOGER_CONSULTATION,
    title: '',
    priceText: '',
    amount: 0.99,
    currency: 'usd',
    textLineThrough: '',
  },
];

const chatTimerTooltip: ChatTooltipConfig = {
  title: '',
  subtitle: '',
  buttonTitle: '',
  timer: 15,
  enabled: true,
};

const astroeventModalConfig: AstroeventModalConfig = {
  enable: true,
  timeout: 20,
  noChatShowSession: 3,
  noChatCloseRepeat: 2,
  hasChatShowSession: 2,
  hasChatCloseRepeat: 2,
  hasChatResetShow: 2,
};

const verticalProductsSubscriptionDefaultParamsIos: SubscriptionVertical = {
  title: '',
  hideSecurityText: false,
  subscribeOnToggleProduct: false,
  showTerms: true,
  animatedBtn: true,
  priceText: 'Start your 3-day FREE trial',
  hidePriceInfo: false,
  closeBtnTimeout: 0,
  priceLocation: PRICE_LOCATION.ABOVE,
  buttonsTextsPosition: TEXTS_ALIGN.LEFT,
  buttonsWithCheckbox: true,
  buttons: [
    {
      productId: PRODUCTS.MONTH_F,
      badgeTitle: '',
      badgeAlign: BADGE_ALIGN.RIGHT,
      subTitle: '{priceweek}',
      title: '',
      rightText: '',
      rightBottomText: '',
      btnTitle: '',
    },
    {
      productId: PRODUCTS.WEEK_H,
      isActive: true,
      badgeTitle: '',
      badgeAlign: BADGE_ALIGN.RIGHT,
      subTitle: '{priceweekwop}/week',
      title: 'Week - Now {price}',
      rightText: '3 days free',
      rightBottomText: '',
      btnTitle: '',
    },
    {
      productId: PRODUCTS.YEAR_D,
      badgeTitle: '90% save',
      badgeAlign: BADGE_ALIGN.RIGHT,
      subTitle: '',
      title: 'Year - First {price}',
      rightText: '{priceweek}',
      rightBottomText: '{price}',
      btnTitle: '',
    },
  ],
};

const verticalProductsSubscriptionDefaultParamsAndroid: SubscriptionVertical = {
  title: '',
  hideSecurityText: false,
  subscribeOnToggleProduct: false,
  showTerms: true,
  animatedBtn: true,
  priceText: '',
  hidePriceInfo: true,
  closeBtnTimeout: 0,
  priceLocation: PRICE_LOCATION.NONE,
  buttonsTextsPosition: TEXTS_ALIGN.LEFT,
  buttonsWithCheckbox: true,
  buttons: [
    {
      productId: PRODUCTS.YEAR_C,
      badgeTitle: 'Save 92%',
      badgeAlign: BADGE_ALIGN.LEFT,
      subTitle: '{price}',
      title: '{period}',
      rightText: '{priceweek}🔥',
      rightBottomText: '',
      btnTitle: '',
      isActive: true,
    },
    {
      productId: PRODUCTS.MONTH_D,
      badgeTitle: 'Save 50%',
      badgeAlign: BADGE_ALIGN.LEFT,
      subTitle: '{price}',
      title: '{period}',
      rightText: '{priceweek}',
      rightBottomText: '',
      btnTitle: '',
    },
    {
      productId: PRODUCTS.WEEK_F,
      badgeTitle: '',
      badgeAlign: BADGE_ALIGN.LEFT,
      subTitle: '{price}',
      title: '{period}',
      rightText: '{priceweek}',
      rightBottomText: '',
      btnTitle: '',
    },
  ],
};

const familySubscriptionDefaultParams: SubscriptionFamily = {
  isSwitchEnabledByDefault: true,
  [SUB_FAMILY_TYPES.BASIC]: {
    hideSecurityText: false,
    subscribeOnToggleProduct: false,
    showTerms: true,
    animatedBtn: true,
    priceLocation: PRICE_LOCATION.NONE,
    priceText: 'Free for your family',
    hidePriceInfo: false,
    closeBtnTimeout: 0,
    isCrossedPriceText: true,
    buttonsTextsPosition: TEXTS_ALIGN.RIGHT,
    buttonsWithCheckbox: true,
    buttons: [
      {
        badgeTitle: 'Save 88%',
        badgeAlign: BADGE_ALIGN.LEFT,
        productId: PRODUCTS.YEAR_E,
        title: '{period}',
        subTitle: '{priceweek}🔥',
        isActive: true,
        rightText: '{price}',
        rightBottomText: '',
        btnTitle: '',
      },
      {
        badgeTitle: 'Save 50%',
        badgeAlign: BADGE_ALIGN.LEFT,
        productId: PRODUCTS.MONTH_C,
        title: '{period}',
        subTitle: '{priceweek}',
        isActive: false,
        rightText: '{price}',
        rightBottomText: '',
        btnTitle: '',
      },
      {
        badgeTitle: '',
        badgeAlign: BADGE_ALIGN.LEFT,
        productId: PRODUCTS.WEEK_L,
        title: '{period}',
        subTitle: '{priceweek}',
        isActive: false,
        rightText: '{price}',
        rightBottomText: '',
        btnTitle: '',
      },
    ],
  },
  [SUB_FAMILY_TYPES.FAMILY]: {
    subscribeOnToggleProduct: false,
    priceLocation: PRICE_LOCATION.NONE,
    closeBtnTimeout: 0,
    priceText: 'Free for your family',
    isCrossedPriceText: false,
    hidePriceInfo: false,
    hideSecurityText: false,
    buttonsTextsPosition: TEXTS_ALIGN.RIGHT,
    buttonsWithCheckbox: true,
    showTerms: true,
    animatedBtn: true,
    buttons: [
      {
        badgeTitle: 'Save 88%',
        badgeAlign: BADGE_ALIGN.LEFT,
        productId: PRODUCTS.FAMILY_YEAR,
        title: '{period}',
        subTitle: '{priceweek}🔥',
        isActive: true,
        rightText: '{price}',
        rightBottomText: '',
        btnTitle: '',
      },
      {
        badgeTitle: 'Save 58%',
        badgeAlign: BADGE_ALIGN.LEFT,
        productId: PRODUCTS.FAMILY_MONTH,
        title: '{period}',
        subTitle: '{priceweek}🔥',
        isActive: false,
        rightText: '{price}',
        rightBottomText: '',
        btnTitle: '',
      },
      {
        badgeTitle: '',
        badgeAlign: BADGE_ALIGN.LEFT,
        productId: PRODUCTS.WEEK_E,
        title: '{period}',
        subTitle: '{introprice}/1st week',
        isActive: false,
        rightText: '{price}',
        rightBottomText: '',
        btnTitle: 'TRY 7-DAY TRIAL',
      },
    ],
  },
};

const palmSubscriptionParams: SubscriptionPalm = {
  title: '',
  btnTitle: '',
  priceText: '',
  animatedBtn: true,
  product: Platform.select({
    ios: PRODUCTS.WEEK_H,
    android: PRODUCTS.WEEK_A,
  }) as string,
  closeBtnTimeout: 0,
  priceLocation: PRICE_LOCATION.INSIDE,
  showTerms: Platform.select<boolean>({
    android: true,
    ios: false,
  }),
};

const postSubscriptionParams: SubscriptionPost = {
  title: '',
  subTitle: '',
  btnTitle: '',
  priceText: '',
  hideBtnBadge: false,
  btnBadgeTitle: '',
  animatedBtn: true,
  product: Platform.select({
    ios: PRODUCTS.WEEK_H,
    android: PRODUCTS.WEEK_F,
  }) as string,
  benefits: [],
  closeBtnTimeout: 0,
  priceLocation: PRICE_LOCATION.NONE,
  showTerms: false,
};

const verticalOneProductSubscriptionParams: SubscriptionVerticalOneProduct = {
  title: '',
  btnTitle: '',
  priceText: '',
  animatedBtn: true,
  product: Platform.select({
    ios: PRODUCTS.WEEK_N,
    android: PRODUCTS.WEEK_A,
  }) as string,
  benefits: [],
  closeBtnTimeout: 0,
  priceLocation: PRICE_LOCATION.NONE,
  showTerms: Platform.select<boolean>({
    android: true,
    ios: false,
  }),
  hideSecurityText: false,
  btnBadgeTitle: '',
  hideBtnBadge: false,
};

const subBenefitsSubscriptionParams: SubscriptionSubBenefits = {
  title: '',
  subTitle: '',
  btnTitle: '',
  priceText: '',
  productId: Platform.select({
    ios: PRODUCTS.WEEK_N,
    android: PRODUCTS.WEEK_A,
    web: WEB_SUBSCRIPTION_ID,
  }) as string,
  closeBtnTimeout: 0,
  productsByCurrency: {
    EUR: '',
    CAD: '',
    AUD: '',
  },
  showTerms: Platform.select({
    android: false,
    ios: false,
    web: false,
  }) as boolean,
  hideSecurityText: false,
};

const subscriptionPromoOffer: SubscriptionPromoOffer = {
  title: '',
  subTitle: '',
  hideSecurityText: false,
  showTerms: true,
  animatedBtn: true,
  priceText: '',
  hidePriceInfo: false,
  closeBtnTimeout: 0,
  priceLocation: PRICE_LOCATION.ABOVE,
  buttonsTextsPosition: TEXTS_ALIGN.LEFT,
  buttonsWithCheckbox: true,
  buttons: [
    {
      productId: PRODUCTS.PRO_W,
      offerId: OFFERS.T,
      badgeTitle: '',
      badgeAlign: BADGE_ALIGN.RIGHT,
      subTitle: '{offer}',
      title: 'Promo',
      rightText: '{price}/{period}',
      rightBottomText: '',
      btnTitle: '',
    },
    {
      productId: PRODUCTS.PRO_W,
      offerId: OFFERS.T_A,
      isActive: true,
      badgeTitle: '',
      badgeAlign: BADGE_ALIGN.RIGHT,
      subTitle: '{offer}',
      title: 'Promo 2',
      rightText: '{price}/{period}',
      rightBottomText: '',
      btnTitle: '',
    },
    {
      productId: PRODUCTS.PRO_W,
      offerId: OFFERS.T_B,
      badgeTitle: '90% save',
      badgeAlign: BADGE_ALIGN.RIGHT,
      subTitle: '{offer}',
      title: 'Promo 3',
      rightText: '{price}/{period}',
      rightBottomText: '',
      btnTitle: '',
    },
  ],
};

export const defaultParams: ParamItem[] = [
  {
    name: 'isHoroscopeRandom',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'isNotificationBadgeVisible',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'pushConfig',
    type: ParamTypes.JSON,
    default: pushConfig,
  },
  {
    name: 'isOnboarding',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'fbAEMenabled',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'surveyConfig',
    type: ParamTypes.JSON,
    default: surveyConfig,
  },
  {
    name: 'authZendeskToken',
    type: ParamTypes.STRING,
    default: authZendeskToken,
  },
  {
    name: 'contactUsMethod',
    type: ParamTypes.STRING,
    default: CONTACT_US_METHODS.HELPDESK,
  },
  {
    name: 'helpdeskConfig',
    type: ParamTypes.JSON,
    default: HELPDESK_CONFIG,
  },
  {
    name: 'isDevScreenEnabled',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'onboardingLoadingDuration',
    type: ParamTypes.NUMBER,
    default: 10000,
  },
  {
    name: 'onboardingLoadingAutoSkipDelay',
    type: ParamTypes.NUMBER,
    default: -1,
  },
  {
    name: 'onbPalmSkipBtnPlace',
    type: ParamTypes.STRING,
    default: SKIP_BUTTON_PLACE.TOP,
  },
  {
    name: 'whereNavigateAfterOnboarding',
    type: ParamTypes.STRING,
    default: '',
  },
  {
    name: 'showUpdateButtonOnYourDayWidget',
    type: ParamTypes.BOOLEAN,
    default: Platform.select({
      ios: true,
      android: false,
      web: false,
    }) as boolean,
  },
  {
    name: 'showCancelSubscriptionBtn',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'subAfterBackgroundSessionStart',
    type: ParamTypes.NUMBER,
    default: Platform.select({
      ios: 0,
      android: 3,
    }) as number,
  },
  {
    name: 'availableSignInWithEmail',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'availableSignInSocial',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'voiceCommandSuggestionMaxSeenTimes',
    type: ParamTypes.NUMBER,
    default: {
      ios: 100,
      android: 0,
      web: 100,
    },
  },
  {
    name: 'voiceCommandSuggestionDisplayTimeout',
    type: ParamTypes.NUMBER,
    default: {
      ios: 4800000,
      android: 0,
      web: 4800000,
    },
  },
  {
    name: 'tabBarBadges',
    type: ParamTypes.JSON,
    default: {
      [ROUTES.ADVISORS]: TAB_BAR_BADGES_TYPES.NUMBER,
      [ROUTES.DAILY]: TAB_BAR_BADGES_TYPES.NUMBER,
      [ROUTES.PROFILE]: TAB_BAR_BADGES_TYPES.NONE,
    },
  },
  {
    name: 'todayWidgets',
    type: ParamTypes.JSON,
    default: WEB_WIDGETS_LIST,
  },
  {
    name: 'readingsWidgets',
    type: ParamTypes.JSON,
    default: {
      ios: [READING_PAGE_WIDGETS.PALMISTRY],
      android: [READING_PAGE_WIDGETS.PALMISTRY, READING_PAGE_WIDGETS.MAGIC_BALL],
      web: [READING_PAGE_WIDGETS.PALM_READING_REPORT, READING_PAGE_WIDGETS.TAROT, READING_PAGE_WIDGETS.MAGIC_BALL],
    },
  },
  {
    name: 'cardOfDayWidgetBtnText',
    type: ParamTypes.STRING,
    default: null,
  },
  {
    name: 'summaryWidgetConfig',
    type: ParamTypes.JSON,
    default: {
      birthChartBlockEnabled: false,
      tagsEnabled: false,
      maxResponseWaitingTime: 60,
      enableHoroscopesV2: {
        day: true,
        week: true,
        month: true,
        year: true,
      },
      pid: {
        day: 2,
        week: 3,
        month: 4,
        year: 5,
      },
    },
  },
  {
    name: 'compatibilityWidgets',
    type: ParamTypes.JSON,
    default: COMPATIBILITY_WIDGETS_LIST,
  },
  {
    name: 'randomWidgetsSequence',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'guidesBanners',
    type: ParamTypes.JSON,
    default: {
      [GUIDES_BANNERS.ZODIAC_COMPATIBILITY]: true,
      [GUIDES_BANNERS.BIRTH_CHART]: true,
      [GUIDES_BANNERS.BIORHYTHMS]: true,
      [GUIDES_BANNERS.DATING]: true,
      [GUIDES_BANNERS.MARRIAGE_COMPATIBILITY]: true,
      [GUIDES_BANNERS.FIND_TRUE_LOVE]: true,
      [GUIDES_BANNERS.PALM_READING]: true,
    },
  },
  {
    name: 'randomGuidesSequence',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'guideQuestionnaire',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'guideStyle',
    type: ParamTypes.STRING,
    default: 'row',
  },
  {
    name: 'rateUs',
    type: ParamTypes.JSON,
    default: rateUs,
  },
  {
    name: 'sessionInterval',
    type: ParamTypes.NUMBER,
    default: 0,
  },
  {
    name: 'sessionsType',
    type: ParamTypes.STRING,
    default: 'bot',
  },
  {
    name: 'unlockAllSessions',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'modalCloseType',
    type: ParamTypes.STRING,
    default: MODAL_CLOSE_TYPES.CROSS,
  },
  {
    name: 'storyDurationTime',
    type: ParamTypes.NUMBER,
    default: 15000,
  },
  {
    name: 'prdReportsTimeout',
    type: ParamTypes.NUMBER,
    default: 1, //minutes
  },
  {
    name: 'prdInstructionEnabled',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'prdDetectLinesEnabled',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'prdDetectionEnabled',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'prdPointsAnimationDuration',
    type: ParamTypes.NUMBER,
    default: 1500,
  },
  {
    name: 'feedbackUrl',
    type: ParamTypes.STRING,
    default: 'https://docs.google.com/forms/d/e/1FAIpQLSde7jEQEZG3D44aASfqNgYHS24lGolla9FBnQ0gpXMLrGX_Qg/viewform',
  },
  {
    name: 'feedbackSession',
    type: ParamTypes.NUMBER,
    default: 20000,
  },
  {
    name: 'defaultSubscriptionScreen',
    type: ParamTypes.STRING,
    default: {
      ios: SUB_SCREENS.VERTICAL_ONE,
      android: SUB_SCREENS.VERTICAL_ONE,
      web: SUB_SCREENS.SUB_BENEFITS,
    },
  },
  {
    name: 'astrologersProduct',
    type: ParamTypes.STRING,
    default: {
      ios: PRODUCTS.LIFETIME,
      android: '',
      web: 'com.horoastroline.premium.lifetime.c',
    },
  },
  {
    name: 'supportEmail',
    type: ParamTypes.STRING,
    default: SUPPORT_EMAIL,
  },
  {
    name: 'supportEmailWeb',
    type: ParamTypes.STRING,
    default: SUPPORT_EMAIL_WEB,
  },
  {
    name: 'policyLink',
    type: ParamTypes.STRING,
    default: {
      ios: 'https://appmakingpub.b-cdn.net/Appmaking%20Privacy%20Policy.html',
      android: 'https://appmakingpub.b-cdn.net/Appmaking%20Privacy%20Policy.html',
      web: 'https://quiz.atrix-app.com/appsella/privacy-policy',
    },
  },
  {
    name: 'termsLink',
    type: ParamTypes.STRING,
    default: {
      ios: 'https://appmakingpub.b-cdn.net/Appmaking%20General%20Terms.html',
      android: 'https://appmakingpub.b-cdn.net/Appmaking%20General%20Terms.html',
      web: 'https://quiz.atrix-app.com/appsella/terms-of-use',
    },
  },
  {
    name: 'onboardingFlow',
    type: ParamTypes.JSON,
    default: {
      ios: [
        ONBOARDING_IDS.WELCOME,
        ONBOARDING_IDS.NAME,
        ONBOARDING_IDS.GENDER,
        ONBOARDING_IDS.DATE_OF_BIRTH,
        ONBOARDING_IDS.RELATIONSHIP,
        ONBOARDING_IDS.PROCESSING,
        ONBOARDING_IDS.SUB_FAMILY,
      ],
      android: [ONBOARDING_IDS.NAME, ONBOARDING_IDS.DATE_OF_BIRTH, ONBOARDING_IDS.PROCESSING, ONBOARDING_IDS.SUB_VERTICAL_ONE],
      web: [
        ONBOARDING_IDS.WELCOME,
        ONBOARDING_IDS.NAME,
        ONBOARDING_IDS.GENDER,
        ONBOARDING_IDS.DATE_OF_BIRTH,
        ONBOARDING_IDS.RELATIONSHIP,
        ONBOARDING_IDS.PROCESSING,
        ONBOARDING_IDS.SUB_WEB,
      ],
    },
  },
  {
    name: 'advisorsOnboardingFlow',
    type: ParamTypes.JSON,
    default: [
      ADVISORS_ONB_PAGES_IDS.WELCOME,
      ADVISORS_ONB_PAGES_IDS.PAIN,
      ADVISORS_ONB_PAGES_IDS.BONUS,
      ADVISORS_ONB_PAGES_IDS.SKILLS,
      ADVISORS_ONB_PAGES_IDS.QUESTIONS,
      ADVISORS_ONB_PAGES_IDS.ANALYSIS,
    ],
  },
  {
    name: 'advisorsOnboardingInappType',
    type: ParamTypes.STRING,
    default: ASTROLOGERS_ONBOARDING_INAPP.OFFER,
  },
  {
    name: 'advisorsOnboardingSkipConnection',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'advisorsCatalogBlocks',
    type: ParamTypes.JSON,
    default: [
      ADVISORS_CATALOG_CUSTOM_BLOCK_IDS.RANDOM,
      ADVISORS_CATALOG_CUSTOM_BLOCK_IDS.CHATS,
      ADVISORS_CATEGORIES.RECOMMENDED,
      ADVISORS_CATEGORIES.LOVE,
      ADVISORS_CATEGORIES.ACCURATE,
      ADVISORS_CATALOG_CUSTOM_BLOCK_IDS.ALL,
    ],
  },
  {
    name: 'advisorsCatalogStealthCategoryPid',
    type: ParamTypes.JSON,
    default: defaultAdvisorsCatalogStealthCategoryPid,
  },
  {
    name: 'advisorsPid',
    type: ParamTypes.NUMBER,
    default: 15,
  },
  {
    name: SUB_TYPES.VERTICAL,
    type: ParamTypes.JSON,
    default: Platform.select({
      ios: verticalProductsSubscriptionDefaultParamsIos,
      android: verticalProductsSubscriptionDefaultParamsAndroid,
      default: verticalProductsSubscriptionDefaultParamsIos,
    }),
  },
  {
    name: SUB_TYPES.FAMILY,
    type: ParamTypes.JSON,
    default: familySubscriptionDefaultParams,
  },
  {
    name: SUB_TYPES.PALM,
    type: ParamTypes.JSON,
    default: palmSubscriptionParams,
  },
  {
    name: SUB_TYPES.POST,
    type: ParamTypes.JSON,
    default: postSubscriptionParams,
  },
  {
    name: SUB_TYPES.VERTICAL_ONE,
    type: ParamTypes.JSON,
    default: verticalOneProductSubscriptionParams,
  },
  {
    name: SUB_TYPES.SUB_BENEFITS,
    type: ParamTypes.JSON,
    default: subBenefitsSubscriptionParams,
  },
  {
    name: 'subBenefitsBannerEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  // promo offers for user reactivation
  {
    name: SUB_TYPES.PROMO_OFFER,
    type: ParamTypes.JSON,
    default: subscriptionPromoOffer,
  },
  {
    name: 'promoOffers',
    type: ParamTypes.JSON,
    default: {
      enabled: true,
      showLimitPerSession: 3,
      showSessionsLimit: 7,
    },
  },
  {
    name: 'subscriptionTimer',
    type: ParamTypes.JSON,
    default: subscriptionTimer,
  },
  {
    name: 'subscriptionSpecialOffer',
    type: ParamTypes.JSON,
    default: subscriptionSpecialOffer,
  },
  {
    name: 'monetizationConfig',
    type: ParamTypes.JSON,
    default: {
      ios: monetizationConfigIos,
      android: monetizationConfigAndroid,
      web: monetizationConfigWeb,
    },
  },
  {
    name: 'subscriptions',
    type: ParamTypes.JSON,
    default: subscriptions,
  },
  {
    name: 'products',
    type: ParamTypes.JSON,
    default: products,
  },
  {
    name: 'rescan',
    type: ParamTypes.JSON,
    default: rescan,
  },
  {
    name: 'remoteNotificationsEnabled',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'notificationsOnStart',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'notificationsOnDashboardSession',
    type: ParamTypes.NUMBER,
    default: 0,
  },
  //pwa
  {
    name: 'notificationCenter',
    type: ParamTypes.JSON,
    default: {
      replaceTimeout: 7,
      closeBtnSessionStartNumber: 3,
      pwaInstructions: {
        enabled: true,
        title: '',
        sessionNumber: 17,
        sessionStartNumber: 1,
      },
      pdfReports: {
        enabled: false,
        title: '',
        text: '',
      },
      whatsapp: {
        enabled: false,
        title: '',
        text: '',
        sessionNumber: 2,
        maxShowCount: 3,
      },
      messengers: {
        enabled: false,
        title: '',
        text: '',
        sessionNumber: 1,
        maxShowCount: 3,
      },
    },
  },
  {
    name: 'pwaInstructionsV2',
    type: ParamTypes.JSON,
    default: {
      gifIconEnabled: true,
      sessionStartNumber: 1000,
      showSessionsLimit: 10,
      timeout: 0,
      closeBtnTimeout: 0,
    },
  },
  {
    name: 'astrologistConfig',
    type: ParamTypes.JSON,
    default: {
      connectionTimeout: 5,
      connectionCloseTimeout: 15,
      connectionButtonTitle: '',
      reconnectionTimeout: 5,
      animated: false,
      enableAstrologistReview: true,
      freeMinutes: 1,
      freeMinutesForFreeUsers: 1,
      pingTimeout: 15,
      title: '',
      btnTitle: '',
      blurMessageEnabled: true,
      skipConnection: false,
    },
  },
  {
    name: 'astrologistNotificationConfig',
    type: ParamTypes.JSON,
    default: {
      enabled: true,
      categories: [ADVISOR_NOTIFICATIONS.CONTEXT, ADVISOR_NOTIFICATIONS.TRANSIT, ADVISOR_NOTIFICATIONS.RELATIONSHIP, ADVISOR_NOTIFICATIONS.GENERAL],
      daysOfRandomMessages: 3,
    },
  },
  {
    name: 'reactivateCloseButton',
    type: ParamTypes.JSON,
    default: {
      link: '',
      title: '',
    },
  },
  {
    name: 'pdfReportsModalConfig',
    type: ParamTypes.JSON,
    default: {
      title: '',
      text: '',
      btnTitle: '',
      timeout: 40,
      sessionNumber: 10000,
      showSessionsLimit: 1,
    },
  },
  {
    name: 'webOnetimeProductsDetails',
    type: ParamTypes.JSON,
    default: webOnetimeProductsDetails,
  },
  {
    name: 'isSkipAuthOnPressHiddenButtonEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'birthChartAvailableForInAppFromDate',
    type: ParamTypes.STRING,
    default: '2023-09-01',
  },
  {
    name: 'birthChartTransitsTitle',
    type: ParamTypes.STRING,
    default: null,
  },
  {
    name: 'birthChartBannerTargetTab',
    type: ParamTypes.STRING,
    default: TOP_TABS_IDS.CHARTS,
  },
  {
    name: 'chatTimerTooltip',
    type: ParamTypes.JSON,
    default: chatTimerTooltip,
  },
  {
    name: 'astrologersMonetizationConfig',
    type: ParamTypes.JSON,
    default: astrologersMonetizationConfig,
  },
  {
    name: 'astrologersSecondsLeftToEnableTriggers',
    type: ParamTypes.NUMBER,
    default: 60,
  },
  {
    name: 'astrologerChatBtnTitle',
    type: ParamTypes.STRING,
    default: null,
  },
  {
    name: 'astrologerChatDisclaimer',
    type: ParamTypes.JSON,
    default: {
      enabled: false,
      expanded: false,
    },
  },
  {
    name: 'astroeventModalConfig',
    type: ParamTypes.JSON,
    default: astroeventModalConfig,
  },
  {
    name: 'astrologerFreeMinutesForRenew',
    type: ParamTypes.NUMBER,
    default: 3,
  },
  {
    name: 'astrologerFreeMinutesModalBtnTitle',
    type: ParamTypes.STRING,
    default: null,
  },
  {
    name: 'astrologerFreeMinutesModalSession',
    type: ParamTypes.NUMBER,
    default: 999999999,
  },
  {
    name: 'minRateToTrustPilot',
    type: ParamTypes.NUMBER,
    default: 3,
  },
  {
    name: 'astrologersPromoConfig',
    type: ParamTypes.JSON,
    default: {
      places: {
        [ASTROLOGERS_PROMO_PLACES.DAILY_TIPS]: true,
        [ASTROLOGERS_PROMO_PLACES.COMPATIBILITY]: true,
        [ASTROLOGERS_PROMO_PLACES.TRANSIT]: true,
        [ASTROLOGERS_PROMO_PLACES.LUCKY_COLOR]: true,
        [ASTROLOGERS_PROMO_PLACES.LUCKY_NUMBER]: true,
        [ASTROLOGERS_PROMO_PLACES.LUCKY_TIME]: true,
        [ASTROLOGERS_PROMO_PLACES.BIORHYTHM]: true,
        [ASTROLOGERS_PROMO_PLACES.LUNAR_CALENDAR]: true,
        [ASTROLOGERS_PROMO_PLACES.TAROT]: true,
        [ASTROLOGERS_PROMO_PLACES.HOUSES]: true,
        [ASTROLOGERS_PROMO_PLACES.PLANETS]: true,
        [ASTROLOGERS_PROMO_PLACES.PALM_READING]: true,
      },
      buttonTitle: '',
    },
  },
  {
    name: 'messengersModalConfig',
    type: ParamTypes.JSON,
    default: {
      enabled: true,
      buttons: {
        email: true,
      },
      title: '',
      buttonTitle: '',
    },
  },
  {
    name: 'isNewYearBgEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'removeIdfmFromUrl',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'todayMatchesBlockV2Enabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'autoRefillModalConfig',
    type: ParamTypes.JSON,
    default: {
      refillTimer: 5,
      enableCloseButton: true,
    },
  },
  {
    name: 'enableAdvisorPalmReadingFirstSession',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'astrologerConsultationPacks',
    type: ParamTypes.JSON,
    default: astrologerConsultationPacks,
  },
  {
    name: 'astrologerConsultationDiscountPacks',
    type: ParamTypes.JSON,
    default: astrologerConsultationDiscountPacks,
  },
  {
    name: 'astrologerPackSpecialOffer',
    type: ParamTypes.JSON,
    default: astrologerPackSpecialOffer,
  },
  {
    name: 'astrologerPackWelcomeOffer',
    type: ParamTypes.JSON,
    default: astrologerPackWelcomeOffer,
  },
  {
    name: 'astrologerPackSpecialOffer2',
    type: ParamTypes.JSON,
    default: astrologerPackSpecialOffer2,
  },
  {
    name: 'astrologerPacksFlows',
    type: ParamTypes.JSON,
    default: astrologerPacksFlows,
  },
  {
    name: 'isOneClickEnabled',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'advisorSoftCurrencyConfig',
    type: ParamTypes.JSON,
    default: advisorSoftCurrencyConfig,
  },
  {
    name: 'tarotCardOfTheDayCalendarEnabled',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'astrologistBackendConfig',
    type: ParamTypes.JSON,
    default: {
      er: false,
      sendPayments: true,
      symbolsPerSecond: 5,
      palmReadingEnabled: false,
      maxTimeOfDelay: 30,
    },
  },
  {
    name: 'receiveTarot',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'astroCalendarConfig',
    type: ParamTypes.JSON,
    default: defaultAstroCalendarConfig,
  },
  {
    name: 'astroCalendarNotificationConfig',
    type: ParamTypes.JSON,
    default: defaultAstroCalendarNotificationConfig,
  },
  {
    name: 'astroCalendarTutorialEnabled',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'astroCalendarWidgetTitle',
    type: ParamTypes.STRING,
    default: null,
  },
  {
    name: 'astroCalendarWidgetButtonTitle',
    type: ParamTypes.STRING,
    default: null,
  },
  {
    name: 'astroCalendarCompleteFirstTodoEnabled',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'tallyQuestionnaireConfig',
    type: ParamTypes.JSON,
    default: {
      enabled: false,
      link: '',
      showCloseButton: true,
    },
  },
  {
    name: 'availablePaymentMethods',
    type: ParamTypes.JSON,
    default: {
      ios: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.APPLE_PAY, PAYMENT_METHODS.PAYPAL],
      android: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.GOOGLE_PAY, PAYMENT_METHODS.PAYPAL],
      web: [PAYMENT_METHODS.CARD, PAYMENT_METHODS.PAYPAL],
    },
  },
  {
    name: 'advisorReviewTipsConfig',
    type: ParamTypes.JSON,
    default: {
      enabled: true,
      availablePaymentMethods: [],
      products: {
        [ASTROLOGER_TIP_PRODUCTS.ASTROLOGER_TIP_1]: {
          price: 1.99,
          activePrice: '$1.99',
          currency: 'USD',
          EUR: {
            price: 1,
            activePrice: '€1',
          },
          CAD: {
            price: 1,
            activePrice: '$1 CAD',
          },
          AUD: {
            price: 1,
            activePrice: '$1 AUD',
          },
        },
        [ASTROLOGER_TIP_PRODUCTS.ASTROLOGER_TIP_3]: {
          price: 3.99,
          activePrice: '$3.99',
          currency: 'USD',
          EUR: {
            price: 3,
            activePrice: '€3',
          },
          CAD: {
            price: 3,
            activePrice: '$3 CAD',
          },
          AUD: {
            price: 3,
            activePrice: '$3 AUD',
          },
        },
        [ASTROLOGER_TIP_PRODUCTS.ASTROLOGER_TIP_5]: {
          price: 5.99,
          activePrice: '$5.99',
          currency: 'USD',
          EUR: {
            price: 5,
            activePrice: '€5',
          },
          CAD: {
            price: 5,
            activePrice: '$5 CAD',
          },
          AUD: {
            price: 5,
            activePrice: '$5 AUD',
          },
        },
        [ASTROLOGER_TIP_PRODUCTS.ASTROLOGER_TIP_10]: {
          price: 10.99,
          activePrice: '$10.99',
          currency: 'USD',
          EUR: {
            price: 10,
            activePrice: '€10',
          },
          CAD: {
            price: 10,
            activePrice: '$10 CAD',
          },
          AUD: {
            price: 10,
            activePrice: '$10 AUD',
          },
        },
      },
    },
  },
  {
    name: 'installMobileNotification',
    type: ParamTypes.JSON,
    default: {
      promocode: 'rnw_to_app',
      enabled: false,
      closeEnabled: true,
    },
  },
  {
    name: 'installMobileAppModalConfig',
    type: ParamTypes.JSON,
    default: {
      closeBtnTimeout: 0,
      subtitle: '',
      title: '',
      showSessionLimits: 5,
      enabled: false,
      promocode: 'rnw_to_app',
    },
  },
  {
    name: 'appLink',
    type: ParamTypes.STRING,
    default: 'https://astroline.go.link?adj_t=kc97xjm&adj_campaign=rnw%20%7C%7C%7C%20',
  },
  {
    name: 'loyaltyCriteria',
    type: ParamTypes.JSON,
    default: {},
  },
  {
    name: 'advisorsMyChatsListEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'advisorHoroscopeEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'advisorHoroscopeChatMsgEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'advisorHoroscopeTimerDuration',
    type: ParamTypes.NUMBER,
    default: 3600,
  },
  {
    name: 'advisorTabs',
    type: ParamTypes.JSON,
    default: [TOP_TAB_IDS.ABOUT, TOP_TAB_IDS.REVIEWS],
  },
  {
    name: 'advisorsServicesConfig',
    type: ParamTypes.JSON,
    default: {
      enabled: false,
      showPricesInList: true,
      showPricesInBtn: true,
      navigateAfterEndChatEnabled: true,
      products: {
        astrologer_service_1: {
          price: 1.99,
          activePrice: '$1.99',
          currency: 'USD',
          EUR: {
            price: 1.99,
            activePrice: '€1.99',
          },
          CAD: {
            price: 1.99,
            activePrice: '$1.99 CAD',
          },
          AUD: {
            price: 1.99,
            activePrice: '$1.99 AUD',
          },
        },
        astrologer_service_2: {
          price: 2.99,
          activePrice: '$2.99',
          currency: 'USD',
          EUR: {
            price: 2.99,
            activePrice: '€2.99',
          },
          CAD: {
            price: 2.99,
            activePrice: '$2.99 CAD',
          },
          AUD: {
            price: 2.99,
            activePrice: '$2.99 AUD',
          },
        },
        astrologer_service_3: {
          price: 3.99,
          activePrice: '$3.99',
          currency: 'USD',
          EUR: {
            price: 3.99,
            activePrice: '€3.99',
          },
          CAD: {
            price: 3.99,
            activePrice: '$3.99 CAD',
          },
          AUD: {
            price: 3.99,
            activePrice: '$3.99 AUD',
          },
        },
        astrologer_service_4: {
          price: 4.99,
          activePrice: '$4.99',
          currency: 'USD',
          EUR: {
            price: 4.99,
            activePrice: '€4.99',
          },
          CAD: {
            price: 4.99,
            activePrice: '$4.99 CAD',
          },
          AUD: {
            price: 4.99,
            activePrice: '$4.99 AUD',
          },
        },
        astrologer_service_5: {
          price: 5.99,
          activePrice: '$5.99',
          currency: 'USD',
          EUR: {
            price: 5.99,
            activePrice: '€5.99',
          },
          CAD: {
            price: 5.99,
            activePrice: '$5.99 CAD',
          },
          AUD: {
            price: 5.99,
            activePrice: '$5.99 AUD',
          },
        },
        astrologer_service_6: {
          price: 6.99,
          activePrice: '$6.99',
          currency: 'USD',
          EUR: {
            price: 6.99,
            activePrice: '€6.99',
          },
          CAD: {
            price: 6.99,
            activePrice: '$6.99 CAD',
          },
          AUD: {
            price: 6.99,
            activePrice: '$6.99 AUD',
          },
        },
        astrologer_service_7: {
          price: 7.99,
          activePrice: '$7.99',
          currency: 'USD',
          EUR: {
            price: 7.99,
            activePrice: '€7.99',
          },
          CAD: {
            price: 7.99,
            activePrice: '$7.99 CAD',
          },
          AUD: {
            price: 7.99,
            activePrice: '$7.99 AUD',
          },
        },
        astrologer_service_8: {
          price: 199,
          activePrice: '$199',
          currency: 'USD',
          EUR: {
            price: 199,
            activePrice: '€199',
          },
          CAD: {
            price: 199,
            activePrice: '$199 CAD',
          },
          AUD: {
            price: 199,
            activePrice: '$199 AUD',
          },
        },
        astrologer_service_9: {
          price: 299,
          activePrice: '$299',
          currency: 'USD',
          EUR: {
            price: 299,
            activePrice: '€299',
          },
          CAD: {
            price: 299,
            activePrice: '$299 CAD',
          },
          AUD: {
            price: 299,
            activePrice: '$299 AUD',
          },
        },
        astrologer_service_10: {
          price: 399,
          activePrice: '$399',
          currency: 'USD',
          EUR: {
            price: 399,
            activePrice: '€399',
          },
          CAD: {
            price: 399,
            activePrice: '$399 CAD',
          },
          AUD: {
            price: 399,
            activePrice: '$399 AUD',
          },
        },
      },
    },
  },
  {
    name: 'paymentFailedTriggerNotificationEnabled',
    type: ParamTypes.BOOLEAN,
    default: true,
  },
  {
    name: 'todayContentTipsEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'todayContentDoDontEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'todayContentPid',
    type: ParamTypes.JSON,
    default: {
      horoscopesTips: 25,
      horoscopesDoDont: 28,
      horoscopesTipsDoDont: 31,
    },
  },
  {
    name: 'whereNavigateAfterOnboardingPWA',
    type: ParamTypes.STRING,
    default: '',
  },
  {
    name: 'sendAdvisorTime',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'localizations',
    type: ParamTypes.JSON,
    default: {
      ar: {},
      de: {},
      en: {},
      es: {},
      fr: {},
      pt: {},
      ja: {},
    },
  },
  {
    name: 'enableVoiceMessages',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'trustPilotPromoConfig',
    type: ParamTypes.JSON,
    default: {
      enabled: false,
      promocode: '',
      title: '',
      btnTitle: '',
    },
  },
  {
    name: 'birthChartApiUrl',
    type: ParamTypes.STRING,
    default: 'prod',
  },
  {
    name: 'enableIncreasingTimer',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'avatarHoroscopeLabelEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'birthChartReportInSubEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'advisorsSuggests',
    type: ParamTypes.JSON,
    default: {
      newSuggestsEnabled: false,
      selectedCategory: '',
    },
  },
  {
    name: 'advisorOnlineStatusesEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'stealthModeEnabled',
    type: ParamTypes.BOOLEAN,
    default: {
      ios: true,
      android: false,
      web: false,
    },
  },
  {
    name: 'readingChatWithInputFlowEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
  {
    name: 'greyEndChatBtnEnabled',
    type: ParamTypes.BOOLEAN,
    default: false,
  },
];
