import { ZodiacSign } from '@wowmaking/birthchart';

import { ADVISOR_NOTIFICATIONS } from 'constants/notification-center';

import api from '..';

import { Question, AdvisorNotification, SuggestsResponseData } from './interfaces';

const QUESTIONS = '/questions/ask';
const ASTROLOGY_QUESTIONS = '/astrology-questions';

export const sendDataToAstrologers = (email: string, question: string, sign?: ZodiacSign): Promise<Question> => {
  return api.fetch(`${QUESTIONS}`, {
    method: 'POST',
    data: {
      email,
      question,
      sign,
    },
  });
};

export const sendAstrologersQuestion = (question: string): Promise<null> => {
  return api.fetch(`${ASTROLOGY_QUESTIONS}/create-question`, {
    method: 'POST',
    data: {
      question,
    },
  });
};

export const getAdvisorSuggests = async (): Promise<SuggestsResponseData | null> => {
  try {
    const res = api.fetch(`${ASTROLOGY_QUESTIONS}/suggests`, {
      method: 'GET',
    });

    return res || null;
  } catch (err) {
    console.log('ERROR getAdvisorSuggests', err);
  }

  return null;
};

export const getNotification = (categories: ADVISOR_NOTIFICATIONS[], transit: string): Promise<AdvisorNotification> => {
  return api.fetch(`${ASTROLOGY_QUESTIONS}/get-notification`, {
    method: 'POST',
    data: {
      categories,
      params: { transit },
    },
  });
};
