import React, { FC, memo } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';

import AdvisorAvatar from 'components/advisors/avatar';
import { Astrologist } from 'api/astrology-chat/constants';

interface Props {
  advisor: Astrologist;
  id: number | string;
  unreadCount: number;
  onPress: (id: number | string) => void;
}

const ChatPreviewItem: FC<Props> = ({ advisor, id, onPress, unreadCount }) => {
  const handlePress = () => {
    onPress(id);
  };

  return (
    <TouchableOpacity style={styles.root} onPress={handlePress}>
      <AdvisorAvatar advisor={advisor} size={'medium'} nameEnabled unreadCount={unreadCount} />
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  root: {
    marginRight: 16,
  },
});

export default memo(ChatPreviewItem);
