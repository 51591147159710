import React, { FC, memo, useCallback } from 'react';
import { StyleSheet, View, ScrollView } from 'react-native';
import { fs, paddingHorizontal, paddingVertical } from '@wowmaking/ui/src/utils';
import _ from 'lodash';

import Text from 'components/text';
import * as COLORS from 'constants/colors';
import { useAppDispatch, useAppSelector } from 'store';
import { navigate } from 'store/navigation/actions';
import { ADVISORS_ALL } from 'constants/routes';
import { selectAnAdvisor } from 'store/astrologers/catalog/actions';
import { t } from 'localization';
import Analytics from 'analytics';
import { getActiveAdvisors } from 'store/astrologers/selectors';
import { TOP_TAB_IDS } from 'screens/advisors/constants';

import AdvisorCard from '../advisor-card';
import { ADVISORS_CATALOG_CUSTOM_BLOCK_IDS } from '../../types';
import ActionButton from '../../../../components/action-button';

import ViewAll from './view-all';

const NUMBER_OF_ADVISORS_PREVIEWS = 12;

interface Props {
  categoryId: ADVISORS_CATALOG_CUSTOM_BLOCK_IDS;
}

const AllAdvisorsBlock: FC<Props> = ({ categoryId }) => {
  const dispatch = useAppDispatch();

  const advisors = useAppSelector(state => getActiveAdvisors(state));
  const sliceAdvisors = _.take(advisors, NUMBER_OF_ADVISORS_PREVIEWS);

  const handleViewAllPress = () => {
    Analytics.trackEvent('AdvisorsCatalog', 'AdvisorsViewAll_Click');
    navigate(ADVISORS_ALL);
  };

  const handleItemPress = useCallback(
    (id: string | number, destination: TOP_TAB_IDS) => {
      Analytics.trackEvent('AdvisorsCatalog', 'Advisor_Click', {
        id,
        category: categoryId,
      });

      dispatch(selectAnAdvisor(id, destination));
    },
    [categoryId],
  );

  if (!sliceAdvisors.length) {
    return null;
  }

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <Text font={'Philosopher'} style={styles.title}>
          {t('ADVISORS_CATALOG.ALL.TITLE')}
        </Text>
        <ActionButton title={t('ADVISORS_CATALOG.ALL.VIEW_ALL')} onPress={handleViewAllPress} />
      </View>

      <ScrollView
        style={styles.scrollContainer}
        contentContainerStyle={styles.cardContainer}
        showsHorizontalScrollIndicator={false}
        horizontal={true}>
        {sliceAdvisors.map(item => {
          return <AdvisorCard key={item.astrologer_id} advisor={item} onPress={handleItemPress} isPreview />;
        })}

        <ViewAll onPress={handleViewAllPress} />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    width: '100%',
    marginBottom: paddingVertical(30),
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: paddingVertical(14),
  },
  title: {
    fontSize: fs(25),
    fontWeight: '700',
    color: COLORS.BEIGE_2,
  },
  scrollContainer: {
    marginHorizontal: paddingHorizontal(-15),
  },
  cardContainer: {
    paddingHorizontal: paddingHorizontal(15),
  },
});

export default memo(AllAdvisorsBlock);
