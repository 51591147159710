import React, { ReactNode, forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { StyleSheet, View, TouchableOpacity, ViewStyle, StyleProp, TextStyle } from 'react-native';
import { color, sw } from '@wowmaking/ui/src/utils';
import { useIsFocused } from '@react-navigation/native';

import * as COLORS from 'constants/colors';
import Icon from 'components/icon';
import { getGlobalPosition } from 'utils/global-position';

import TooltipTile from './tooltip-tile';
import { DEFAULT_ICON_SIZE, DEFAULT_TOOLTIP_WIDTH } from './constants';

export type TooltipTilePlacements = 'top' | 'bottom' | 'auto';

export interface PositionCoords {
  pageX: number;
  pageY: number;
}

export interface TooltipRef {
  toggleTooltip: () => void;
  showTooltip: () => void;
  closeTooltip: () => void;
}

interface Props {
  content?: ReactNode;
  iconSize?: number;
  iconOpacity?: number;
  tooltipText?: string;
  tooltipTextStyle?: StyleProp<TextStyle>;
  tooltipWidth?: number;
  tooltipPlacement?: TooltipTilePlacements;
  style?: StyleProp<ViewStyle>;
  withIcon?: boolean;
  fixedHorizontalOffset?: boolean;
  closingTimeout?: number;
  onShow?: () => void;
  onClose?: () => void;
}

const Tooltip = forwardRef<TooltipRef, Props>(
  (
    {
      content = null,
      tooltipText = '',
      tooltipTextStyle = null,
      iconSize = DEFAULT_ICON_SIZE,
      iconOpacity = 1,
      tooltipWidth = DEFAULT_TOOLTIP_WIDTH,
      tooltipPlacement = 'top',
      style = null,
      withIcon = false,
      fixedHorizontalOffset = false,
      closingTimeout = 0,
      onShow,
      onClose,
    },
    ref,
  ) => {
    const isFocused = useIsFocused();
    const coordsMarkRef = useRef<View>(null);
    const scaleCoeff = iconSize / DEFAULT_ICON_SIZE;

    const [showTooltip, setShowTooltip] = useState(false);
    const [position, setPosition] = useState<PositionCoords>({ pageX: 0, pageY: 0 });

    const handleTooltipToggle = () => {
      handleTrackPosition();
      setShowTooltip(prev => {
        if (prev) {
          onClose?.();
        } else {
          onShow?.();
        }

        return !prev;
      });
    };

    useImperativeHandle(ref, () => ({
      toggleTooltip: () => handleTooltipToggle(),
      showTooltip: () => {
        handleTrackPosition();
        setShowTooltip(true);
      },
      closeTooltip: () => setShowTooltip(false),
    }));

    const handleTrackPosition = async () => {
      const { pageX, pageY } = await getGlobalPosition(coordsMarkRef);
      setPosition({ pageX, pageY });
    };

    useEffect(() => {
      let timeout: ReturnType<typeof setTimeout> | null = null;

      if (closingTimeout > 0 && showTooltip) {
        timeout = setTimeout(() => {
          setShowTooltip(false);
        }, closingTimeout);
      }

      return () => {
        if (timeout !== null) {
          clearTimeout(timeout);
        }
      };
    }, [closingTimeout, showTooltip]);

    useEffect(() => {
      if (showTooltip && !isFocused) {
        setShowTooltip(false);
      }
    }, [isFocused]);

    const iconWrapperStyles = {
      width: iconSize,
      height: iconSize,
      backgroundColor: showTooltip ? COLORS.GOLDEN : color(COLORS.GOLDEN, 0.3),
      opacity: iconOpacity,
    };

    return (
      <View style={[styles.root, style]}>
        <TouchableOpacity onPress={handleTooltipToggle} activeOpacity={showTooltip ? 1 : 0.2}>
          {withIcon ? (
            <View style={[styles.iconTooltipWrapper, iconWrapperStyles]}>
              <Icon name={'question'} color={showTooltip ? COLORS.DARK_BLUE_GREY_2 : COLORS.GOLDEN} size={sw(12 * scaleCoeff)} />
            </View>
          ) : null}
          <TooltipTile
            isVisible={showTooltip}
            text={tooltipText}
            content={content}
            position={position}
            iconSize={withIcon ? iconSize : 0}
            tooltipTileWidth={tooltipWidth}
            tooltipTextStyle={tooltipTextStyle}
            placement={tooltipPlacement}
            fixedHorizontalOffset={fixedHorizontalOffset}
            onClose={handleTooltipToggle}
          />
        </TouchableOpacity>
        <View ref={coordsMarkRef} collapsable={false} style={styles.coordMark} />
      </View>
    );
  },
);

const styles = StyleSheet.create({
  root: {
    zIndex: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconTooltipWrapper: {
    borderRadius: 100,
    backgroundColor: color(COLORS.GOLDEN, 0.3),
    justifyContent: 'center',
    alignItems: 'center',
  },
  coordMark: {
    width: 1,
    height: 1,
    position: 'absolute',
    alignSelf: 'center',
  },
});

export default memo(Tooltip);
