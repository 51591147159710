import React, { FC, useEffect } from 'react';
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native';
import { BOTTOM_OFFSET, fs, paddingHorizontal, paddingVertical, sw } from '@wowmaking/ui/src/utils';
import Modal from 'react-native-modal';
import { isEmpty } from 'lodash';

import Analytics from 'analytics';
import { RootState, useAppDispatch, useAppSelector } from 'store';
import { closeCurrentModal } from 'store/modals/actions';
import { navigate } from 'store/navigation/actions';
import { t } from 'localization';
import Text from 'components/text';
import Button from 'components/button';
import HighLightText from 'components/hight-light-text';
import * as COLORS from 'constants/colors';
import * as MODALS from 'constants/modals';
import { ADVISORS_CONNECTION } from 'constants/routes';
import { ASTROLOGERS_OPEN_PLACE } from 'screens/advisors/constants';
import { formatMinutes } from 'utils/astrologist';
import { setCompleted as setAdvisorsOnboardingCompleted } from 'store/astrologers/onboarding/actions';
import { navigateWithMonetization } from 'store/unlock-content/actions';
import * as TRIGGERS from 'constants/monetization-triggers';
import { selectIsPremiumWithoutForcedOnlineAdvisor, selectOnlineAdvisors } from 'store/astrologers/selectors';

import ICON_CROSS from '../images/beige-cross.png';

import IMAGE from './images/image.png';

interface Props {}

const WebFreeConsultationModal: FC<Props> = () => {
  const dispatch = useAppDispatch();

  const isVisible = useAppSelector((state: RootState) => state.modals.activeModal === MODALS.FREE_CONSULTATION);
  const btnTitleText = useAppSelector((state: RootState) => state.remoteConfig.astrologerFreeMinutesModalBtnTitle);
  const seconds = useAppSelector((state: RootState) => state.astrologers.time.seconds);
  const advisor = useAppSelector(state => state.astrologers.core.astrologist);
  const onlineAdvisors = useAppSelector(selectOnlineAdvisors);
  const isPremium = useAppSelector(state => selectIsPremiumWithoutForcedOnlineAdvisor(state, advisor));

  const btnTitle = !btnTitleText ? t('ASTROLOGIST_CHAT.FREE_CONSULTATION_MODAL.BTN_TITLE') : btnTitleText;

  const time = formatMinutes(seconds);

  useEffect(() => {
    if (isVisible) {
      Analytics.trackEvent('Astrologist_Free_Consultation_Modal', 'Show');
    }
  }, [isVisible]);

  const handleButtonPress = () => {
    Analytics.trackEvent('Astrologist_Free_Consultation_Modal', 'Button_Click');

    if (isEmpty(onlineAdvisors)) {
      return dispatch(closeCurrentModal());
    }

    dispatch(setAdvisorsOnboardingCompleted());

    const route = ADVISORS_CONNECTION;
    const params = { place: ASTROLOGERS_OPEN_PLACE.RENEW };

    if (isPremium) {
      dispatch(
        navigateWithMonetization({
          trigger: TRIGGERS.PREMIUM_ADVISOR_START_CHAT,
          route,
          params,
        }),
      );
    } else {
      navigate(route, params);
    }

    dispatch(closeCurrentModal());
  };

  const handleClosePress = () => {
    Analytics.trackEvent('Astrologist_Free_Consultation_Modal', 'Close');
    dispatch(closeCurrentModal());
  };

  const handleSkip = () => {
    Analytics.trackEvent('Astrologist_Free_Consultation_Modal', 'Skip');
    dispatch(closeCurrentModal());
  };

  return (
    <Modal style={styles.wrapper} isVisible={isVisible} animationInTiming={500} backdropColor={COLORS.SURVEY_MODAL_BACKGROUND}>
      <View style={styles.container}>
        <Image style={styles.image} source={IMAGE} resizeMode="contain" />

        <Text font="Philosopher" style={styles.title}>
          {t('ASTROLOGIST_CHAT.FREE_CONSULTATION_MODAL.TITLE')}
        </Text>

        <HighLightText style={styles.subTitle} highlightedTextStyle={styles.subTitleHightLight}>
          {t('ASTROLOGIST_CHAT.FREE_CONSULTATION_MODAL.TEXT', { number: time })}
        </HighLightText>

        <Button titleStyle={styles.btnTitle} style={styles.button} title={btnTitle} onPress={handleButtonPress} />
        <Button
          isTransparent
          title={t('ASTROLOGIST_CHAT.FREE_CONSULTATION_MODAL.SKIP_BTN_TITLE')}
          fontFamily="OpenSans"
          titleStyle={styles.skip}
          onPress={handleSkip}
        />

        <TouchableOpacity style={styles.cross} onPress={handleClosePress}>
          <Image source={ICON_CROSS} />
        </TouchableOpacity>
      </View>
    </Modal>
  );
};

export default WebFreeConsultationModal;

const styles = StyleSheet.create({
  wrapper: {
    flex: 1,
    justifyContent: 'flex-end',
    margin: 0,
  },
  container: {
    paddingBottom: BOTTOM_OFFSET + paddingVertical(20),
    paddingTop: paddingVertical(40),
    paddingHorizontal: paddingHorizontal(32),
    borderTopLeftRadius: sw(40),
    borderTopRightRadius: sw(40),
    backgroundColor: COLORS.MODAL_BG_COLOR,
    alignItems: 'center',
  },
  image: {
    width: sw(285),
  },
  title: {
    color: COLORS.BEIGE_2,
    marginTop: paddingVertical(31),
    fontSize: fs(26),
    fontWeight: '700',
    lineHeight: fs(32),
    textAlign: 'center',
  },
  subTitle: {
    marginTop: paddingVertical(10),
    fontSize: fs(16),
    lineHeight: fs(23),
    color: COLORS.BEIGE,
    opacity: 0.8,
    textAlign: 'center',
  },
  subTitleHightLight: {
    fontWeight: '700',
  },
  cross: {
    position: 'absolute',
    top: paddingVertical(20),
    right: paddingHorizontal(20),
  },
  button: {
    marginTop: paddingVertical(40),
  },
  btnTitle: {
    fontWeight: '600',
    fontSize: fs(20),
  },
  skip: {
    fontSize: fs(14),
    color: COLORS.AQUA,
    fontWeight: '400',
    textDecorationLine: 'underline',
  },
});
