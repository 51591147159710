import { AppState } from 'react-native';

import { PROFILE_TYPES } from '../profile/types';

import {
  SET_APP_STATUS,
  SET_IS_FULL_PROFILE,
  SET_IS_LOADED,
  SET_INTERNET_CONNECTION,
  SET_WAS_ON_CONTENT_PAGES,
  SET_OVERLAY_LOADER_ACTIVITY,
  ActionType,
  State,
  SET_ROLL_UP_PUSH_ON_DASHBOARD_SHOWN,
  SET_IS_ANALYTICS_INITED,
  SET_WEB_MODE,
  SET_INITIAL_SYSTEM_LOCALE,
  SET_INITIAL_URL,
  SET_INSTALL_VERSION,
  SET_MUTATOR_IS_FETCHED,
} from './types';

const initialState: State = {
  isInternetConnected: true,
  isFullProfile: false,
  isLoaded: false,
  isAnalyticsInited: false,
  appStatus: AppState.currentState,
  wasOnContentPages: false,
  isOverlayLoaderActive: false,
  isRollUpPushOnDashboardShown: false,
  initialSystemLocale: null,
  webMode: undefined,
  initialUrl: null,
  installVersion: null,
  isMutatorFetched: false,
};

export default function (state = initialState, action: ActionType): State {
  switch (action.type) {
    case SET_APP_STATUS:
      return {
        ...state,
        appStatus: action.payload,
      };

    case SET_WAS_ON_CONTENT_PAGES:
      return {
        ...state,
        wasOnContentPages: true,
      };

    case SET_IS_FULL_PROFILE:
      return {
        ...state,
        isFullProfile: true,
      };

    case SET_INTERNET_CONNECTION:
      return {
        ...state,
        isInternetConnected: action.payload,
      };

    case SET_IS_LOADED:
      return {
        ...state,
        isLoaded: true,
      };

    case SET_IS_ANALYTICS_INITED:
      return {
        ...state,
        isAnalyticsInited: true,
      };

    case SET_OVERLAY_LOADER_ACTIVITY:
      return {
        ...state,
        isOverlayLoaderActive: !state.isOverlayLoaderActive,
      };

    case SET_ROLL_UP_PUSH_ON_DASHBOARD_SHOWN:
      return {
        ...state,
        isRollUpPushOnDashboardShown: true,
      };

    case SET_INITIAL_SYSTEM_LOCALE:
      return {
        ...state,
        initialSystemLocale: action.payload,
      };

    case SET_WEB_MODE:
      return {
        ...state,
        webMode: action.payload,
      };

    case SET_INITIAL_URL:
      return {
        ...state,
        initialUrl: action.payload,
      };

    case SET_INSTALL_VERSION:
      return {
        ...state,
        installVersion: action.payload,
      };

    case SET_MUTATOR_IS_FETCHED:
      return {
        ...state,
        isMutatorFetched: true,
      };

    case PROFILE_TYPES.RESET_USER_DATA:
      return {
        ...initialState,
        initialUrl: state.initialUrl,
      };

    default:
      return state;
  }
}
