import AsyncStorage from '@react-native-async-storage/async-storage';
import { applyMiddleware, combineReducers, createStore, Action, compose } from 'redux';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import promoOffers from 'modules/promo-offers/store';

import addresses from './addresses';
import app from './app';
import astroCalendar from './astro-calendar';
import astrologers from './astrologers';
import auth from './auth';
import badges from './badges';
import bestMatches from './best-matches';
import billing from './billing';
import biorhythms from './biorhythms';
import birthChart from './birth-chart';
import birthChartsCompatibility from './birth-charts-compatibility';
import calendars from './calendars';
import compatibility from './compatibility';
import compatibilityReports from './compatibility-reports';
import dailyTips from './daily-tips';
import datingCalendar from './dating-calendar';
import guides from './guides';
import horoscope from './horoscope';
import lunarCalendar from './lunar-calendar';
import matches from './matches';
import modals from './modals';
import moonPhases from './moon-phases';
import navigation from './navigation';
import onboarding from './onboarding';
import palmReadingDaily from './palm-reading-daily';
import profile from './profile';
import rateUs from './rate-us';
import remoteConfig from './remote-config';
import retrograde from './retrograde-planets';
import siriShortcuts from './siri-shorcuts';
import stories from './stories';
import survey from './survey';
import tarot from './tarot';
import todaysFeatures from './todays-features';
import transits from './transits';
import unlockContent from './unlock-content';
import widgets from './widgets';
import notification from './notification';
import pwa from './pwa';
import upsails from './upsails';
import notificationCenter from './notification-center';
import messengers from './messengers';

const persistConfigs = {
  navigation: {
    timeout: 0,
    key: 'navigation',
    storage: AsyncStorage,
    whitelist: ['isNavigatedAfterOnboarding'],
  },
  siriShortcuts: {
    timeout: 0,
    key: 'siri-shortcuts',
    storage: AsyncStorage,
    blacklist: ['suggestionVisible'],
  },
  auth: {
    timeout: 0,
    key: 'auth',
    storage: AsyncStorage,
    whitelist: ['webUUID'],
    blacklist: ['isSuccessful'],
  },
  onboarding: {
    timeout: 0,
    key: 'onboarding',
    storage: AsyncStorage,
    blacklist: ['step'],
  },
  horoscope: {
    timeout: 0,
    key: 'horoscope',
    storage: AsyncStorage,
    whitelist: ['zodiacAnimationCount'],
  },
  badges: {
    timeout: 0,
    key: 'badges',
    storage: AsyncStorage,
    blacklist: [],
  },
  promoOffers: {
    timeout: 0,
    key: 'promoOffers',
    storage: AsyncStorage,
    blacklist: ['showCountInCurrentSession', 'inited'],
  },
  widgets: {
    timeout: 0,
    key: 'widgets',
    storage: AsyncStorage,
    blacklist: ['widgets'],
  },
  color: {
    timeout: 0,
    key: 'color',
    storage: AsyncStorage,
    blacklist: [],
  },
  guides: {
    timeout: 0,
    key: 'guides',
    storage: AsyncStorage,
    blacklist: ['sessions'],
  },
  compatibility: {
    timeout: 0,
    key: 'compatibility',
    storage: AsyncStorage,
    whitelist: ['pick', 'isBannerVisible'],
  },
  rateUs: {
    timeout: 0,
    key: 'rateUs',
    storage: AsyncStorage,
    whitelist: ['rated', 'likeCounter', 'showedOnHome'],
  },
  transits: {
    timeout: 0,
    key: 'transits',
    storage: AsyncStorage,
    whitelist: ['transitsContent', 'aspectsContent', 'astroeventTransit'],
  },
  stories: {
    timeout: 0,
    key: 'stories',
    storage: AsyncStorage,
    blacklist: ['stories'],
  },
  palmReadingDaily: {
    timeout: 0,
    key: 'palmReadingDaily',
    storage: AsyncStorage,
    blacklist: ['preview'],
  },
  birthChart: {
    timeout: 0,
    key: 'birthChart',
    storage: AsyncStorage,
    whitelist: ['onboardingDone', 'step', 'birthPlace', 'birthTime', 'birthHourFormatTime', 'pdfReportShown'],
  },
  modals: {
    timeout: 0,
    key: 'modals',
    storage: AsyncStorage,
    blacklist: ['activeModal'],
  },
  app: {
    timeout: 0,
    key: 'app',
    storage: AsyncStorage,
    whitelist: [
      'isFullProfile',
      'wasOnContentPages',
      'isRollUpPushOnDashboardShown',
      'initialSystemLocale',
      'initialUrl',
      'installVersion',
      'isMutatorFetched',
    ],
  },
  billing: {
    timeout: 0,
    key: 'billing',
    storage: AsyncStorage,
    whitelist: [
      'transactions',
      'purchaseDate',
      'purchasedAstrologers',
      'purchased',
      'isFamilyProductSubscribed',
      'isWebSubscriptionShowed',
      'redemptionSubscription',
    ],
  },
  profile: {
    timeout: 0,
    key: 'profile',
    storage: AsyncStorage,
    blacklist: ['isHeaderOpened', 'isProfile'],
  },
  survey: {
    timeout: 0,
    key: 'survey',
    storage: AsyncStorage,
  },
  notification: {
    timeout: 0,
    storage: AsyncStorage,
    key: 'notification',
    blacklist: ['isWebNotificationRequestOverlayVisible'],
  },
  lunarCalendar: {
    timeout: 0,
    storage: AsyncStorage,
    key: 'lunarCalendar',
  },
  remoteConfig: {
    timeout: 0,
    key: 'remoteConfig',
    blacklist: ['error', 'defaultParams'],
    storage: AsyncStorage,
  },
  tarot: {
    timeout: 0,
    key: 'tarot',
    storage: AsyncStorage,
    blacklist: ['loading', 'imagesCached'],
  },
  pwa: {
    timeout: 0,
    key: 'pwa',
    storage: AsyncStorage,
    blacklist: ['isPWAAutoInstallAvailable', 'installMobileAppSeconds'],
  },
  upsails: {
    timeout: 0,
    key: 'upsails',
    storage: AsyncStorage,
  },
  messengers: {
    timeout: 0,
    key: 'messengers',
    storage: AsyncStorage,
    blacklist: ['botId'],
  },
  astroCalendar: {
    timeout: 0,
    key: 'astroCalendar',
    storage: AsyncStorage,
    whitelist: ['tutorialShown'],
  },
  birthChartsCompatibility: {
    timeout: 0,
    key: 'birthChartsCompatibility',
    storage: AsyncStorage,
    blacklist: ['onboardingFlow', 'onboardingStep'],
  },
};

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  navigation: persistReducer(persistConfigs.navigation, navigation),
  profile: persistReducer(persistConfigs.profile, profile),
  remoteConfig,
  app: persistReducer(persistConfigs.app, app),
  tarot: persistReducer(persistConfigs.tarot, tarot),
  palmReadingDaily: persistReducer(persistConfigs.palmReadingDaily, palmReadingDaily),
  auth: persistReducer(persistConfigs.auth, auth),
  unlockContent,
  matches,
  moonPhases,
  retrograde,
  calendars,
  dailyTips,
  biorhythms,
  compatibilityReports,
  bestMatches,
  addresses,
  modals: persistReducer(persistConfigs.modals, modals),
  todaysFeatures,
  lunarCalendar: persistReducer(persistConfigs.lunarCalendar, lunarCalendar),
  datingCalendar,
  astrologers,
  stories: persistReducer(persistConfigs.stories, stories),
  transits: persistReducer(persistConfigs.transits, transits),
  horoscope: persistReducer(persistConfigs.horoscope, horoscope),
  badges: persistReducer(persistConfigs.badges, badges),
  billing: persistReducer(persistConfigs.billing, billing),
  onboarding: persistReducer(persistConfigs.onboarding, onboarding),
  siriShortcuts: persistReducer(persistConfigs.siriShortcuts, siriShortcuts),
  promoOffers: persistReducer(persistConfigs.promoOffers, promoOffers),
  widgets: persistReducer(persistConfigs.widgets, widgets),
  guides: persistReducer(persistConfigs.guides, guides),
  compatibility: persistReducer(persistConfigs.compatibility, compatibility),
  rateUs: persistReducer(persistConfigs.rateUs, rateUs),
  birthChart: persistReducer(persistConfigs.birthChart, birthChart),
  survey: persistReducer(persistConfigs.survey, survey),
  notification: persistReducer(persistConfigs.notification, notification),
  pwa: persistReducer(persistConfigs.pwa, pwa),
  upsails: persistReducer(persistConfigs.upsails, upsails),
  notificationCenter,
  messengers: persistReducer(persistConfigs.messengers, messengers),
  astroCalendar: persistReducer(persistConfigs.astroCalendar, astroCalendar),
  birthChartsCompatibility: persistReducer(persistConfigs.birthChartsCompatibility, birthChartsCompatibility),
});

let persistor: Persistor;

const middlewares = [thunkMiddleware];

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

const Store = {
  init() {
    persistor = persistStore(store);
    return { store, persistor };
  },
  get persistor() {
    return persistor;
  },
  get dispatch(): ThunkDispatch<RootState, unknown, Action> {
    return store.dispatch;
  },
  getState() {
    return store.getState();
  },
};

export { store, persistor };

export type AppDispatch = typeof Store.dispatch;
export type AppGetState = typeof Store.getState;
export type StoreType = typeof store;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default Store;
