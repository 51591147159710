import { ADVISORS_STEALTH_CATEGORIES, ADVISORS_CATEGORIES } from 'screens/advisors/screens/catalog/types';

import { ResetUserData } from '../types';

type CategoriesData = {
  [key in ADVISORS_CATEGORIES | ADVISORS_STEALTH_CATEGORIES]?: (string | number)[];
};

export interface State {
  categories: CategoriesData;
  previousDateForThreeDays: string | null;
  previousDateForSevenDays: string | null;
}

export enum TYPES {
  SET_CATEGORIES = 'ASTROLOGERS/SET_CATEGORIES',
  SET_PREVIOUS_DATE_FOR_THREE_DAYS = 'ASTROLOGERS/SET_PREVIOUS_DATE_FOR_THREE_DAYS',
  SET_PREVIOUS_DATE_FOR_SEVEN_DAYS = 'ASTROLOGERS/SET_PREVIOUS_DATE_FOR_SEVEN_DAYS',
}

interface SetCategories {
  type: typeof TYPES.SET_CATEGORIES;
  payload: CategoriesData;
}

interface SetPreviousDateForThreeDays {
  type: typeof TYPES.SET_PREVIOUS_DATE_FOR_THREE_DAYS;
  payload: string | null;
}

interface SetPreviousDateForSevenDays {
  type: typeof TYPES.SET_PREVIOUS_DATE_FOR_SEVEN_DAYS;
  payload: string | null;
}

export type ActionType = SetCategories | SetPreviousDateForThreeDays | SetPreviousDateForSevenDays | ResetUserData;
